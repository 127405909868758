import styled from 'styled-components';

export const ChoiceContainer = styled.div`
  display: flex;
  width: 50vw;
  height: calc(25vw - 20px);

  @media (max-width: 600px) {
    flex-direction: column;
    height: unset;
    width: 100%;
  }
`;

export const NoteContainer = styled.div`
  position: absolute;
  right: -8px;
  top: -12px;
`;

export const Tap = styled.div`
  width: 100%;
  height: 20vw;

  @media (max-width: 600px) {
    height: 70vw;
    margin: 8px 0;
  }
`;
