import useAdd from './useAdd';
import useCopy from './useCopy';
import useRemove from './useRemove';

const useChange = () => {
  const [add] = useAdd();
  const [remove] = useRemove();
  const [copy] = useCopy();

  const change = (options: Change.Options) => {
    switch (options.type) {
      case 'add': {
        add(options);
        break;
      }
      case 'remove': {
        remove(options);
        break;
      }
      case 'copy': {
        copy(options);
        break;
      }
    }
  };

  return [change];
};

export default useChange;
