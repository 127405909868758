import atoms from '@atoms';
import useHistory from '@hooks/editor/useHistory';
import { getStitch } from '@utils';
import { useRecoilState } from 'recoil';
import useStitchColor from '../application/useStitchColor';

type UseStitchReturn = [
  App.ImmutableStitch | null,
  (overrideIsStitched?: boolean) => void,
]

interface UseStitch {
  (indices: App.Indices): UseStitchReturn
}

const useStitch: UseStitch = (indices) => {
  const { columnIndex, rowIndex } = indices;
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);
  const [color] = useStitchColor();
  const stitch = getStitch(indices, canvas.grid);
  const [, change] = useHistory();


  const getIsStitched = (overrideIsStitched?: boolean) => {
    if (typeof overrideIsStitched !== 'undefined') {
      return overrideIsStitched;
    }

    return !stitch.get('isStitched') || (stitch.get('isStitched') && stitch.get('color') !== color);
  };

  const toggle = (overrideIsStitched?: boolean) => {
    const isStitched = getIsStitched(overrideIsStitched);
    if (stitch) {
      change.write({
        rowIndex,
        columnIndex,
        prevColor: stitch.get('color'),
        prevIsStitched: stitch.get('isStitched'),
        nextColor: color,
        nextIsStitched: isStitched,
      });
    }
    setCanvas((currentCanvas) => {
      const { grid } = currentCanvas;
      const cell = getStitch(indices, grid);
      const nextCell = cell.set('color', color).set('isStitched', isStitched);
      const nextNewGrid = grid.setIn([rowIndex, 'cells', columnIndex], nextCell);

      return {
        ...currentCanvas,
        grid: nextNewGrid,
      };
    });
  };

  return [
    stitch,
    toggle,
  ];
};

export default useStitch;
