import atoms from '@atoms';
import { useRecoilState } from 'recoil';

type UseHasStartedResult = [
  boolean,
  (nextState: boolean, preventNavigation?: boolean) => boolean,
]

interface UseHasStarted {
  (): UseHasStartedResult;
}

const useHasStarted: UseHasStarted = () => {
  const [application, setApplication] = useRecoilState(atoms.application);

  const setHasStarted = (nextState: boolean) => {
    setApplication({
      ...application,
      hasStarted: nextState,
    });
    return nextState;
  };

  return [
    application.hasStarted,
    setHasStarted,
  ];
};

export default useHasStarted;
