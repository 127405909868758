import { useOptionsSingle } from '@hooks';
import { ComboBox, ComboBoxChange, OnSelect, OptionItemType } from 'gestalt';
import React, { FunctionComponent, useState } from 'react';


interface SearchProps {
  options: OptionItemType[]
  resetOptions(): void;
  onResultSelect(option: OnSelect): void;
  handleChange(change: ComboBoxChange): void;
}

const Search: FunctionComponent<SearchProps> = (props) => {
  const [font] = useOptionsSingle('font');
  const { options, onResultSelect, resetOptions, handleChange } = props;
  const [inputValue, setInputValue] = useState<string>(font.family);

  const onFocus = () => {
    resetOptions();
  };

  const onClear = () => {
    setInputValue('');
    resetOptions();
  };

  const onChange = (change: ComboBoxChange) => {
    if (!change.value) {
      setInputValue('');
      handleChange(change);
      return;
    }
    setInputValue(change.value);
    handleChange(change);
  };

  const onSelect = (option: OnSelect) => {
    const { item } = option;
    if (!item) {
      return;
    }
    setInputValue(item.label);
    onResultSelect(option);
  };

  return (
    <ComboBox
      onFocus={onFocus}
      accessibilityClearButtonLabel="Clear font search value"
      id="fontPicker"
      label="Font"
      inputValue={inputValue}
      noResultText="No fonts fount"
      onChange={onChange}
      onSelect={onSelect}
      options={options}
      placeholder="Select Font"
      onClear={onClear}
    />
  );
};

export default Search;
