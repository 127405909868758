import styled from 'styled-components';

interface StitchProps {
  isStitched: boolean
  squareSize: number;
  stitchLength: number;
  hasLeftAfterStitch: boolean;
  currentColor: string;
  stitchColor: string;
}

export const Stitch = styled.div<StitchProps>`
  position: absolute;
  width: ${(props) => props.squareSize * 0.48}px;
  border-radius: ${(props) => props.squareSize * 0.4}px;
  height: ${(props) => props.stitchLength * 0.86}px;
  transform: rotate(45deg);
  top: -${(props) => props.squareSize * 0.7}px;
  right: ${(props) => props.squareSize * 0.7}px;
  box-sizing: content-box;
  border: 1px transparent solid;
  background-color: ${({ isStitched, stitchColor }) => isStitched ? stitchColor : 'transparent'};

  &:hover {
    cursor: none;
    background-color: ${({ currentColor }) => currentColor};
    border: 1px rgba(0, 0, 0, 0.5) solid;
    opacity: ${(props) => {
    const { hasLeftAfterStitch, stitchColor, isStitched, currentColor } = props;

    if (hasLeftAfterStitch === false) {
      return isStitched ? 1 : 0.8;
    }

    const isSame = stitchColor === currentColor;
    return isStitched && isSame ? 0.4 : 0.8;
  }};
  }
`;
