import atoms from '@atoms';
import { useRecoilState } from 'recoil';

interface UseCanvasCell {
  (): [string, (color: App.Color) => void];
}

const useStitchColor: UseCanvasCell = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);

  const setColor = (color: App.Color) => {
    setCanvas({
      ...canvas,
      currentColor: color.hex,
    });

  };

  return [
    canvas.currentColor,
    setColor,
  ];
};

export default useStitchColor;
