import atoms from '@atoms';
import settings from '@settings';
import { useRecoilState } from 'recoil';

type UseDragReturn = [
  App.IsDragging,
  (options?: Partial<App.IsDragging>) => void,
]

interface UseDrag {
  (): UseDragReturn
}

const useDrag: UseDrag = () => {
  const [{ isDragging }, setEditor] = useRecoilState(atoms.editor);

  const toggleDrag = (options?: Partial<App.IsDragging>) => {
    const isDragging = Object.assign({}, settings.DEFAULT_IS_DRAGGING, options);
    setEditor((editor) => ({
      ...editor,
      isDragging,
    }));
  };

  return [
    isDragging,
    toggleDrag,
  ];
};


export default useDrag;
