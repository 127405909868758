import { List } from 'immutable';
import { atom } from 'recoil';

const defaultState: Atoms.Canvas = {
  container: {
    width: 0,
    height: 0,
  },
  controlState: {
    openMenu: null,
  },
  grid: List([]),
  currentColor: '#FFFFFF',
  mouseOption: 'click',
};

const canvas = atom({
  key: 'CanvasAtom',
  default: defaultState,
});

export default canvas;
