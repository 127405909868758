import atoms from '@atoms';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import useSetCanvas from './useSetCanvas';

interface SetGrid {
  (pixels: App.Pixel[]): void;
}
type CanvasResult = [string[], SetGrid];

interface UseCanvas {
  (): CanvasResult
}

const useCanvas: UseCanvas = () => {
  const canvas = useRecoilValue(atoms.canvas);
  const [set] = useSetCanvas();

  const rows = canvas.grid.map((row) => row.get('id'));
  const grid = useMemo(() => rows.toJS() as string[], [rows.size]);

  return [
    grid,
    set,
  ];
};

export default useCanvas;
