import atoms from '@atoms';
import { application } from '@hooks';
import { createEmptyRow } from '@utils';
import { List } from 'immutable';
import { useRecoilState, useSetRecoilState } from 'recoil';

const useSetBlankCanvas = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);
  const [, setLoading] = application.useLoading('all');
  const setApplication = useSetRecoilState(atoms.application);

  const setBlankCanvas = (rowCount: number, columnCount: number) => {
    const newGridBase = Array.from({
      length: rowCount,
    }).map(() =>
      createEmptyRow(columnCount - 1),
    );
    const grid = List(newGridBase);

    setApplication((application) => ({
      ...application,
      hasStarted: true,
    }));

    setCanvas({
      ...canvas,
      grid,
    });
    setLoading(false);
  };

  return [setBlankCanvas];
};

export default useSetBlankCanvas;
