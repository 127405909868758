import CONFIRM_MESSAGES from './confirmMessages';
import MENU_OPTIONS from './options';
import MENU_SECTIONS from './sections';

const getLabel = (option: ActionMenu.MenuOption, state: unknown): string => {
  const { label, whenState, whenFallback } = option;

  if (whenState) {
    const currentState = whenState.find(({ is }) => is === state);

    if (currentState) {
      return `${currentState.pre} ${label}`;
    }
  }

  return `${whenFallback ? `${whenFallback} ` : ''}${label}`;
};

interface BuildMenuOptions {
  getLocalStorageValue<T extends App.LocalStorageKey>(key: string): App.LocalStorageValues[T] | null
}

const buildMenu = (options: BuildMenuOptions): ActionMenu.Section[] => {
  const { getLocalStorageValue } = options;
  const baseOptions = Object
    .values(MENU_OPTIONS)
    .map((menuOption): ActionMenu.Option => ({
      ...menuOption,
      confirmMessage: CONFIRM_MESSAGES.find((message) => message.option === menuOption.value),
      label: getLabel(menuOption, getLocalStorageValue(menuOption.value)),
    }));
  const menuOptions = Object
    .values(MENU_SECTIONS)
    .map((section): ActionMenu.Section | null => {
      const sectionOptions = baseOptions
        .filter((option) => option.sectionId === section.id);

      if (sectionOptions.length === 0) {
        return null;
      }
      return {
        sectionTitle: section.title,
        options: sectionOptions,
      };
    })
    .filter((section): section is ActionMenu.Section => section !== null);

  const optionsWithoutSection = baseOptions
    .filter((option) => !menuOptions.find((section) => section.options.find((sectionOption) => sectionOption.label === option.label)))
    .map((menuOption): ActionMenu.Option => ({
      ...menuOption,
      confirmMessage: CONFIRM_MESSAGES.find((message) => message.option === menuOption.value),
    }));

  const miscSection: ActionMenu.Section = {
    sectionTitle: 'NO_SECTION',
    options: optionsWithoutSection,
  };
  const builtOptions: ActionMenu.Section[] = [...menuOptions, miscSection];

  return builtOptions;
};

export default buildMenu;
