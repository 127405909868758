import { DEFAULT_HOTKEYS, DEFAULT_LOADING } from '@constants';
import { localStorage } from '@utils';
import { atom } from 'recoil';

const applicationAtom = atom({
  key: 'ApplicationAtom',
  default: {
    isDarkMode: localStorage.get('is-dark-mode', false),
    loading: DEFAULT_LOADING,
    hasStarted: false,
    hotkeys: DEFAULT_HOTKEYS,
    startOption: null as App.StartOption | null,
  },
});

export default applicationAtom;
