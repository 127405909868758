import atoms from '@atoms';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

const useHasContent = () => {
  const canvas = useRecoilValue(atoms.canvas);

  const hasContent = useMemo(
    () => canvas.grid.some((row) => row.get('cells').some((cell) => cell && cell.get('isStitched'))),
    [canvas.grid],
  );

  const cells = canvas.grid.get(0)?.get('cells');

  const hasGrid = useMemo(
    () => canvas.grid.size > 0 && cells && cells.size > 0,
    [canvas.grid],
  );
  return [hasContent, hasGrid];
};

export default useHasContent;
