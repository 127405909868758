import atoms from '@atoms';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

type UseStartOptionReturn = [
  App.StartOption | null,
  (startOption: App.StartOption | null, preventNavigation?: boolean) => App.StartOption | null,
]

interface UseStartOption {
  (): UseStartOptionReturn;
}

const useStartOption: UseStartOption = () => {
  const [application, setApplication] = useRecoilState(atoms.application);
  const history = useHistory();

  const setStartOption = (startOption: App.StartOption | null, preventNavigation?: boolean) => {
    if (!preventNavigation && startOption !== null) {
      history.push(`/${startOption}`);
    }
    setApplication({
      ...application,
      startOption: startOption,
    });
    return startOption;
  };

  return [
    application.startOption,
    setStartOption,
  ];
};

export default useStartOption;
