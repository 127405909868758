import { useSize } from '@hooks';
import { Box, Heading, Text } from 'gestalt';
import React, { FunctionComponent } from 'react';

const Header: FunctionComponent = () => {
  const [size] = useSize();
  const { isMobile } = size;

  return (
    <Box
    >
      <Box paddingY={1}>
        <Heading size={isMobile ? 'md' : 'lg'}>
          Needlepoint Studio
        </Heading>
      </Box>
      {!isMobile &&
        <Text size="sm">
          A project by Ashley Matyn and Cameron Aziz. Enjoy.
        </Text>
      }
    </Box>
  );
};

export default Header;
