const darkMode: ActionMenu.MenuOption = {
  label: 'Dark Mode',
  value: 'is-dark-mode',
  whenState: [
    {
      pre: 'Disable',
      is: true,
    },
    {
      pre: 'Enable',
      is: false,
    },
  ],
};

const enableHotkeys: ActionMenu.MenuOption = {
  label: 'Hotkeys',
  value: 'hotkeys-enabled',
  sectionId: 'hotkeys',
  whenState: [
    {
      pre: 'Disable',
      is: true,
    },
    {
      pre: 'Enable',
      is: false,
    },
  ],
  whenFallback: 'Disable',
};

const downloadPDF: ActionMenu.MenuOption = {
  label: 'Download PDF',
  value: 'download-pdf',
  sectionId: 'download',
};

const downloadCSV: ActionMenu.MenuOption = {
  label: 'Download CSV',
  value: 'download-csv',
  sectionId: 'download',
};


const showHotkeyHelp: ActionMenu.MenuOption = {
  label: 'Hotkey Info',
  value: 'hide-hotkey-info',
  sectionId: 'hotkeys',
  whenState: [
    {
      pre: 'Hide',
      is: true,
    },
    {
      pre: 'Show',
      is: false,
    },
  ],
  whenFallback: 'Hide',
};


const clearStitches: ActionMenu.MenuOption = {
  value: 'clear-stitches',
  label: 'Clear Stitches',
};

const clearCanvas: ActionMenu.MenuOption = {
  value: 'clear-canvas',
  label: 'Clear Canvas',
};


const options = {
  enableHotkeys,
  clearStitches,
  clearCanvas,
  showHotkeyHelp,
  downloadPDF,
  downloadCSV,
  darkMode,
} as const;

export default options;
