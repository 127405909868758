import api from '@api';
import { useOptionsSingle, util } from '@hooks';
import { OnSelect, OptionItemType, TextFieldChange } from 'gestalt';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Search from './search';
import * as Styled from './styled';

const buildOptions = (fonts: App.GoogleFont[]): OptionItemType[] => fonts.map((font) => ({
  label: font.family,
  value: font.family,
  subtext: font.variants.join(', '),
}));

const SelectFont: FunctionComponent = () => {
  const [prevent] = util.usePreventFocusBorder();
  const containerRef = useRef<HTMLDivElement>(null);
  const [, setFont] = useOptionsSingle('font');
  const [allFonts, setAllFonts] = useState<App.GoogleFont[]>([]);
  const [allOptions, setAllOptions] = useState<OptionItemType[]>(buildOptions(allFonts));
  const [filteredOptions, setFilteredOptions] = useState<OptionItemType[]>(buildOptions(allFonts));

  useEffect(
    () => {
      if (containerRef.current) {
        prevent(containerRef.current);
      }
    },
    [containerRef.current],
  );

  useEffect(
    () => {
      fetchFonts();
    },
    [],
  );

  const fetchFonts = async () => {
    const fonts = await api.getFonts();
    if (fonts) {
      setAllFonts(fonts.items);
      setAllOptions(buildOptions(fonts.items));
    }
  };

  const handleSearchChange = React.useCallback(
    (change: TextFieldChange) => {
      if (!change.value) {
        setFilteredOptions(buildOptions(allFonts));
        return;
      }
      const re = new RegExp(change.value, 'i');
      const matches = allFonts.filter((font) => font.family.match(re));
      setFilteredOptions(buildOptions(matches));
    },
    [allFonts],
  );

  const getFile = (font: App.GoogleFont) => {
    if (font.variants.includes('regular')) {
      return font.files['regular'];
    }
    if (font.variants.includes('italic')) {
      return font.files['italic'];
    }
    throw new Error('No regular or italic font variant found');
  };

  const onResultSelect = (change: OnSelect) => {
    const { item } = change;
    if (!item) {
      return;
    }

    const font = allFonts.find((f) => f.family === item.value);
    if (!font) {
      return;
    }

    const file = getFile(font);
    const assignment = {
      family: font.family,
      file,
    };
    setFont(assignment);
  };

  const resetOptions = () => {
    setFilteredOptions(allOptions);
  };

  return (
    <Styled.ComboBoxContainer ref={containerRef}>
      <Search
        options={filteredOptions}
        resetOptions={resetOptions}
        onResultSelect={onResultSelect}
        handleChange={handleSearchChange}
      />
    </Styled.ComboBoxContainer>
  );
};

export default SelectFont;
