import { atom } from 'recoil';

const applicationAtom = atom({
  key: 'StitchAtom',
  default: {
    hotkeyPressed: false,
    hoveredStitch: null as Atoms.HoveredStitch,
  },
});

export default applicationAtom;
