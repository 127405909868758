import atoms from '@atoms';
import { getStitch } from '@utils';
import { List } from 'immutable';
import { useRecoilState } from 'recoil';

interface HistoryStats {
  size: number;
  index: number;
  lastHistoryId: string | null;
  history: App.HistoryJSItem[];
  isUpdating: boolean;
}

interface HistoryChange {
  write(change: App.HistoryChange): void,
  move(offset: number): void,
}

type UseStitchReturn = [
  HistoryStats,
  HistoryChange
]

interface UseHistory {
  (): UseStitchReturn
}

interface Change {
  columnIndex: number;
  rowIndex: number;
  color: string;
  isStitched: boolean;
}

const useHistory: UseHistory = () => {
  const [{ history, isUpdating }, setEditor] = useRecoilState(atoms.editor);
  const [canvasState, setCanvas] = useRecoilState(atoms.canvas);

  const revert = (grid: App.Grid, change: Change): App.Grid => {
    const { columnIndex, rowIndex, isStitched, color } = change;
    const cell = getStitch(change, grid);
    const row = grid.get(rowIndex);
    const cells = row?.get('cells');
    const nextCell = cell.set('isStitched', isStitched).set('color', color);
    const nextCells = cells?.set(columnIndex, nextCell);
    if (nextCells && row) {
      const nextRow = row.set('cells', nextCells);
      return grid.set(rowIndex, nextRow);
    }
    return grid;
  };

  const move = (offset: number) => {
    setCanvas((canvas) => {
      const index = history.get('index');
      const items = history.get('items');
      const item = items.get(index + offset);
      // const reversions = items.slice(index + offset);
      // /* ~ LOG */ console.log('~ offset', offset, (() => { const now = new Date(); return `${now.getSeconds()}.${now.getMilliseconds()}`; })());
      // /* ~ LOG */ console.log('~ items.size', items.size, (() => { const now = new Date(); return `${now.getSeconds()}.${now.getMilliseconds()}`; })());
      // /* ~ LOG */ console.log('~ reversions', reversions, (() => { const now = new Date(); return `${now.getSeconds()}.${now.getMilliseconds()}`; })());
      if (!item) {
        return canvas;
      }
      let grid = canvas.grid;
      // reversions.reverse().forEach((item) => {
      item.changes.forEach((change) => {
        const { rowIndex, columnIndex, prevColor, prevIsStitched, nextIsStitched, nextColor } = change;
        const color = offset <= 0 ? prevColor : nextColor;
        const isStitched = offset <= 0 ? prevIsStitched : nextIsStitched;
        const nextGrid = revert(
          grid,
          {
            rowIndex: rowIndex,
            columnIndex,
            color,
            isStitched,
          },
        );
        grid = nextGrid;
      });
      // });
      return {
        ...canvas,
        grid,
      };
    });
    setEditor((editor) => {
      const index = history.get('index');
      const moveAmount = offset === 0 ? -1 : offset;
      const nextHistory = history.set('index', index + moveAmount);
      return {
        ...editor,
        history: nextHistory,
      };
    });
  };

  const getItems = (editor: Atoms.Editor) => {
    const items = editor.history.get('items');
    const index = editor.history.get('index');
    if (items.size > index + 1) {
      return items.slice(index, items.size - index - 1);
    }
    return items;
  };

  const write = (change: App.HistoryChange) => {
    setEditor((editor) => {
      const items = getItems(editor);

      const previous = canvasState.grid.get(change.rowIndex)?.get('cells')?.get(change.columnIndex);
      if (!previous) {
        return editor;
      }
      const changes = List<App.HistoryChange>([change]);
      const nextItems = items.push({
        id: `${Math.random()}`,
        changes,
      });

      const nextHistory = editor.history
        .set('items', nextItems)
        .set('index', items.size);
      return {
        ...editor,
        history: nextHistory,
      };
    });
  };

  const size = history.get('items').size;
  const items = history.get('items').toJS() as App.HistoryJSItem[];

  const stats: HistoryStats = {
    size,
    isUpdating,
    history: items,
    index: history.get('index'),
    lastHistoryId: items[items.length - 1]?.id || null,
  };
  const historyChange: HistoryChange = {
    move,
    write,
  };

  return [
    stats,
    historyChange,
  ];
};

export default useHistory;
