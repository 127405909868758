import { textMagic } from '@utils';
import application from '../application';
import grid from '../grid';
import useOptions from '../useOptions';
import useOptionsSingle from '../useOptionsSingle';

interface ParseText {
  (text: string): Promise<void>
}

type UseParseText = () => [string[], ParseText];

const useParseText: UseParseText = () => {
  const [canvas] = grid.useCanvas();
  const [setCanvas] = grid.useSetCanvas();
  const [options] = useOptions();
  const [, setLoadedFonts] = useOptionsSingle('loadedFontFamilies');
  const [, setLoading] = application.useLoading('all');

  const parse = async (text: string) => {
    if (text.length > 0) {
      const { resolution, font, loadedFontFamilies } = options;

      const magic = await textMagic(text, resolution, font, loadedFontFamilies);

      if (magic.didLoadFont) {
        const nextLoadedFamilies = [font.family, ...loadedFontFamilies];
        setLoadedFonts(nextLoadedFamilies);
      }

      setCanvas(magic.stitches);
      setLoading(false);
    }
  };

  return [
    canvas,
    parse,
  ];
};

export default useParseText;
