import { atom } from 'recoil';

const defaultAtom: Atoms.DialsTuple = [
  {
    value: false,
    toggleRestOnToggle: true,
    label: 'Click',
    key: 'click',
    isDisabled: false,
    icon: 'hand-pointing',
  },
  {
    value: false,
    toggleRestOnToggle: true,
    label: 'Select',
    key: 'select',
    isDisabled: false,
    icon: 'maximize',
  },
  {
    value: false,
    toggleRestOnToggle: true,
    label: 'Copy',
    key: 'copy',
    isDisabled: false,
    icon: 'duplicate',
  },
  {
    value: false,
    toggleRestOnToggle: true,
    label: 'Paste',
    key: 'paste',
    isDisabled: true,
    icon: 'drag-drop',
  },
];

const dialsAtom = atom({
  key: 'DialsAtom',
  default: defaultAtom,
});

export default dialsAtom;
