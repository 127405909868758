import settings from '@settings';

const getFonts = async (): Promise<App.FontsResponse | null> => {
  const url = `https://www.googleapis.com/webfonts/v1/webfonts?key=${settings.GOOGLE_API_KEY}`;
  const response = await fetch(url);
  if (response.body) {
    const body = await response.json();
    return body as App.FontsResponse;
  }

  return null;
};

export default getFonts;
