import { EventHandlerType, OnNavigationArgs } from 'gestalt';
import { useHistory } from 'react-router-dom';

const useOnNavigation = (args: OnNavigationArgs) => {
  const { href } = args;
  const history = useHistory();

  const getPath = (path: string) => {
    switch (path) {
      case '/sign-up': return 'sign-up';
      case '/login': return 'login';
      case '/help': return 'help';
      default: return '/';
    }
  };

  const onNavigationClick: EventHandlerType = ({ event }) => {
    event.preventDefault();
    history.push(getPath(href));
  };

  return onNavigationClick;
};


export default useOnNavigation;
