const getClientOS = (): App.ClientOS => {
  if (navigator.appVersion.indexOf("Win") != -1) {
    return 'windows';
  }

  if (navigator.appVersion.indexOf("Mac") != -1) {
    return 'macintosh';
  }

  if (navigator.appVersion.indexOf("X11") != -1) {
    return 'unix';
  }

  if (navigator.appVersion.indexOf("Linux") != -1) {
    return 'linux';
  }

  return 'unknown';
};

const os = getClientOS();

const getMetaKey = (): string | null => {
  switch (os) {
    case 'macintosh': return '⌘';
    case 'windows': return '[Ctrl]';

    default:
      return null;
  }
};

const metaKey = getMetaKey();

export default {
  os,
  metaKey,
};
