import firebase from 'firebase';


const fetchSave = (id?: string): Promise<App.Save | null | App.NotFound> =>
  new Promise((resolve, reject) => {
    if (id) {
      const database = firebase.database();
      const starCountRef = database.ref('saves/' + id);
      const timeout = setTimeout(
        () => {
          resolve('NOT_FOUND');
        },
        3000,
      );
      starCountRef.on('value', (snapshot) => {
        clearTimeout(timeout);
        const value = snapshot.val();
        resolve(value);
      });
    }
    return resolve(null);
  });

export default fetchSave;
