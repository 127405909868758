import { Box, Button } from 'gestalt';
import React, { FunctionComponent } from 'react';
import * as Styled from './styled';

interface SubmitOptions {
  label: string;
  onClick(): void;
  isDisabled?: boolean
}

interface InputProps {
  submitOptions?: SubmitOptions;
}

const Input: FunctionComponent<InputProps> = (props) => {
  const { children, submitOptions } = props;

  return (
    <Box
      alignSelf="center"
      display="flex"
      direction="column"
      justifyContent="center"
    >
      <Styled.Setup>
        {children}
      </Styled.Setup>
      {submitOptions &&
        <Box
          alignSelf="center"
          marginTop={4}
        >
          <Button
            accessibilityLabel={submitOptions.label}
            color="blue"
            size="lg"
            text={submitOptions.label}
            disabled={submitOptions.isDisabled}
            onClick={submitOptions.onClick}
          />
        </Box>
      }
    </Box>
  );
};

export default Input;
