import { Inputs, Setup, TextRef } from '@components';
import { application, useOptions, useOptionsSingle, useSize } from '@hooks';
import { Box } from 'gestalt';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import Details from './details';

const TextInput: FunctionComponent = () => {
  const [text, setText] = useOptionsSingle('text');
  const [options] = useOptions();
  const [, setHasStarted] = application.useHasStarted();
  const [size] = useSize();
  const { isMobile } = size;
  const history = useHistory();
  const textRef = useRef<TextRef>(null);

  const isReady = options.font.family.length > 0
    && options.resolution > 0
    && options.text.length > 0;


  useEffect(
    () => {
      window.addEventListener('keypress', keypress);

      return () => {
        window.removeEventListener('keypress', keypress);
      };
    },
    [isReady],
  );

  const keypress = (event: KeyboardEvent) => {
    if (event.code === 'Enter' && isReady) {
      accept();
    }
  };

  useEffect(
    () => {
      if (textRef.current) {
        textRef.current.focus();
      }
    },
    [textRef],
  );

  const updateText = (value: string) => {
    setText(value);
  };

  const accept = () => {
    history.push('/studio');
    setHasStarted(true);
  };

  return (
    <Setup
      submitOptions={{
        label: 'Create Canvas',
        isDisabled: !isReady,
        onClick: accept,
      }}
    >
      <Box maxWidth={200} alignSelf="start">
        <Inputs.Text
          ref={textRef}
          value={text}
          label="Text"
          delay={1000}
          onValidInput={updateText}
        />
      </Box>
      <Details />
    </Setup>
  );
};

export default TextInput;
