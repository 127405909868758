import { application } from '@hooks';
import React, { Fragment, FunctionComponent, useRef } from 'react';
import Canvas from './canvas';
import ControlPanel from './controlPanel';

const Editor: FunctionComponent = () => {
  const outputRef = useRef<HTMLDivElement>(null);
  const [hasStarted] = application.useHasStarted();

  return (
    <Fragment>
      <ControlPanel outputRef={outputRef} />
      <Canvas ref={outputRef} />
    </Fragment>
  );
};

export default Editor;
