import { Box } from 'gestalt';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import * as Styled from '../styled';
import Note from './note';
import Wrapper from './wrapper';
interface TapProps {
  onTap(): void;
  onClickDataLayer?: string;
  noteMessage?: string;
  isDisabled?: boolean;
}

const Tap: FunctionComponent<TapProps> = (props) => {
  const { isDisabled, onClickDataLayer, onTap, children, noteMessage } = props;
  const [isHovering, setIsHovering] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      if (boxRef.current) {
        boxRef.current.addEventListener('mouseenter', mouseEnter);
        boxRef.current.addEventListener('mouseleave', mouseLeave);
      }

      return () => {
        if (boxRef.current) {
          boxRef.current.removeEventListener('mouseenter', mouseEnter);
          boxRef.current.removeEventListener('mouseleave', mouseLeave);
        }
      };
    },
    [boxRef.current],
  );


  const mouseEnter = () => {
    setIsHovering(true);
  };

  const mouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Styled.Tap>
      <Box
        ref={boxRef}
        marginEnd={2}
        marginStart={2}
        rounding={4}
        height="100%"
        borderStyle="sm"
      >
        <Wrapper onClickDataLayer={onClickDataLayer} isDisabled={isDisabled} onTap={onTap}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            color={isHovering ? 'navy' : 'blue'}
            padding={3}
            rounding={4}
            height="100%"
          >
            <Box>
              {children}
            </Box>
          </Box>
          {noteMessage &&
            <Note
              message={noteMessage}
            />
          }
        </Wrapper>
      </Box>
    </Styled.Tap>
  );
};

export default Tap;
