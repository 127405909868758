import { application, grid, stitch } from '@hooks';
import { Button, CompositeZIndex, FixedZIndex, Flex, Layer, Modal } from 'gestalt';
import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Content from './content';

interface ConfirmProps {
  close(): void;
  message: ActionMenu.ConfirmMessage;
}

const Confirm: FunctionComponent<ConfirmProps> = (props) => {
  const { close, message } = props;
  const history = useHistory();
  const [clearGrid] = grid.useClear();
  const [clearStitches] = stitch.useClear();
  const [, setStartOption] = application.useStartOption();
  const HEADER_Z_INDEX = new FixedZIndex(10);
  const zIndex = new CompositeZIndex([HEADER_Z_INDEX]);

  useEffect(
    () => {
      window.addEventListener('keydown', keydown);

      return () => {
        window.removeEventListener('keydown', keydown);
      };
    },
    [],
  );

  const keydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close();
    }

    if (event.key === 'Enter') {
      clickDelete();
    }
  };

  const clickDelete = () => {
    switch (message.option) {
      case 'clear-canvas': {
        clearGrid();
        history.replace('/');
        setStartOption(null);
        break;
      }
      case 'clear-stitches': {
        clearStitches();
        break;
      }
    }
    close();
  };

  return (
    <Layer zIndex={zIndex}>
      <Modal
        accessibilityModalLabel="Clear Canvas"
        onDismiss={close}
        size="sm"
        footer={
          <Flex justifyContent="end" gap={2}>
            <Button
              color="gray"
              text="Cancel"
              onClick={close}
            />
            <Button
              color="red"
              text="Delete forever"
              onClick={clickDelete}
            />
          </Flex>
        }
      >
        <Content
          message={message}
        />
      </Modal>
    </Layer>
  );
};

export default Confirm;
