import firebase from 'firebase';

const userCreate = async (email: string, password: string): Promise<App.User | null> => {
  try {
    const userCredential = await firebase.auth()
      .createUserWithEmailAndPassword(email, password);
    if (userCredential.user) {
      return {
        email: userCredential.user.email,
      };
    }
    return null;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    throw new Error(`${errorCode}: ${errorMessage}`);
  }
};


export default userCreate;
