import firebase from 'firebase';

const userLogin = async (email: string, password: string): Promise<App.User | null> => {
  try {
    const userCredential = await firebase.auth()
      .signInWithEmailAndPassword(email, password);
    return userCredential.user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    throw new Error(`${errorCode}: ${errorMessage}`);
  }
};

export default userLogin;
