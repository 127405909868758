import { maxSelector } from '@atoms';
import Position from '@sectors/editor/canvas/position';
import { createEmptyRow } from '@utils';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import * as Styled from './styled';

const ColumnNumbers: FunctionComponent = () => {
  const { columnCount, squareSize } = useRecoilValue(maxSelector);
  const bottomRowRef = useRef<App.CanvasRow>(createEmptyRow(columnCount + 1));

  useEffect(
    () => {
      const blankRow = createEmptyRow(columnCount + 1);
      bottomRowRef.current = blankRow;
    },
    [columnCount],
  );

  const cells = bottomRowRef.current.get('cells');
  return (
    <Styled.Row rowHeight={squareSize}>
      <Styled.ColumnPosition squareHeight={squareSize} squareWidth={squareSize} />
      {cells.map((cell, i) =>
        <Styled.ColumnPosition key={cell.get('id')} squareHeight={squareSize} squareWidth={squareSize}>
          <Position
            index={i}
            isLast={i === columnCount}
            position={`${i + 1}`}
            axis="column"
          />
        </Styled.ColumnPosition>,
      )}
    </Styled.Row>
  );
};

export default ColumnNumbers;
