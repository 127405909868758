import { application } from '@hooks';
import { Heading } from 'gestalt';
import React, { FunctionComponent, useEffect } from 'react';
import Tap from './tap';

const Blank: FunctionComponent = () => {
  const [showHotkeyInfo, createLabel] = application.useShowHotkeyInfo();
  const [registerHotkey, unregisterHotkey] = application.useHotkeys();
  const [, setStartOption] = application.useStartOption();
  const hotkey = 'B';

  useEffect(
    () => {
      registerHotkey(hotkey, onClick);

      return () => {
        unregisterHotkey(hotkey);
      };
    },
    [],
  );

  const onClick = () => {
    setStartOption('blank');
  };

  const buttonText = `Blank${showHotkeyInfo ? createLabel(hotkey) : ''}`;

  return (
    <Tap onTap={onClick} onClickDataLayer="setup-blank">
      <Heading
        size="lg"
        color="white"
      >
        {buttonText}
      </Heading>
    </Tap>
  );
};

export default Blank;
