// import { UI } from '@components';
import { Box, TextField, TextFieldChange } from 'gestalt';
import React, { FunctionComponent, useEffect, useState } from 'react';
// import * as Styled from '../styled';

interface InputProps {
  currentColor: string;
  markInvalid(): void;
  selectColor(color: string): void;
  selectedColor: string | null;
}

const Input: FunctionComponent<InputProps> = (props) => {
  const { selectColor, currentColor, markInvalid, selectedColor } = props;
  const [invalidCharacter, setInvalidCharacter] = useState<boolean>(false);
  const [color, setColor] = useState<string | null>(selectedColor);
  const [colorInput, setColorInput] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      if (colorInput) {
        setColor(colorInput);
      }
    },
    [colorInput],
  );

  useEffect(
    () => {
      setColor(selectedColor);
    },
    [selectedColor],
  );

  const onChange = (change: TextFieldChange) => {
    const value = change.value.toUpperCase();
    const characters = value.startsWith('#') ? value : `#${value ? value : ''}`;
    const allowableCharacters = /^[0-9A-F]+$/;
    const validCharacters = allowableCharacters.test(characters.slice(1)) && characters.length <= 7;
    if (validCharacters || characters === '#') {
      setColorInput(characters.toUpperCase());
      setInvalidCharacter(false);
      return;
    }
    setInvalidCharacter(true);
  };

  const validColor = colorInput && (colorInput.length === 7 || colorInput.length === 4);

  useEffect(
    () => {
      if (!validColor) {
        markInvalid();
        return;
      }

      if (colorInput) {
        selectColor(colorInput);
      }
    },
    [validColor, colorInput],
  );

  return (
    <Box
      marginTop={4}
      marginBottom={4}
      display="flex"
      width="100%"
      justifyContent="center"
    >
      <TextField
        value={color || undefined}
        errorMessage={invalidCharacter ? 'Invalid HEX Code' : undefined}
        id="color-input"
        onChange={onChange}
      />
      {/* {validColor && colorInput &&
        <Styled.HexInput>
          <UI.ColorNode
            isRound
            isFluidWidth
            isFluidHeight
            hex={colorInput}
          />
        </Styled.HexInput>
      } */}
    </Box >
  );
};

export default Input;
