import atoms from '@atoms';
import { useRecoilState } from 'recoil';

const useRemove = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);

  const buildNextGrid = (options: Change.RemoveOptions): App.Grid => {
    const { index, axis } = options;

    if (axis === 'row') {
      const nextGrid = canvas.grid.splice(index, 1);

      return nextGrid;
    }

    return canvas.grid.map((row) => {
      const nextCells = row.get('cells').splice(index, 1);
      const nextRow = row.set('cells', nextCells);
      return nextRow;

    });
  };

  const remove = (options: Change.RemoveOptions) => {
    const nextCanvasGrid = buildNextGrid(options);
    setCanvas({
      ...canvas,
      grid: nextCanvasGrid,
    });
  };

  return [
    remove,
  ];
};

export default useRemove;
