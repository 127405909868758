import styled from 'styled-components';

export const PointerBox = styled.div`
  :hover {
    cursor: pointer;
  }
`;

interface StyledPointerBoxProps {
  isHoverOutline?: boolean;
}

export const StyledPointerBox = styled(PointerBox) <StyledPointerBoxProps>`
  box-sizing: border-box;
  padding: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const UploadInput = styled.input`
  display: none;
`;

export const UploadImage = styled.img`
  cursor: crosshair;
`;

export const DefaultCursor = styled.div`
  cursor: default;
`;

export const PointerCursor = styled.div`
  cursor: pointer;
`;

interface ColorNodeProps {
  hex: string;
  isButton?: boolean;
  isWide?: boolean;
  isSelected?: boolean;
  isFluidWidth?: boolean;
  isFluidHeight?: boolean;
  isRound?: boolean;
  pointerCursor?: boolean;
  width?: string;
}

export const ColorNode = styled.div<ColorNodeProps>`
  background-color: ${props => props.hex};
  height: 20px;
  width: ${({ width }) => width ? width : 'auto'};
  border-radius: ${({ isFluidWidth, isRound }) => {
    if (isRound) {
      return '50%';
    }
    return isFluidWidth ? '20px' : '4px';
  }};
  margin-left: ${({ isFluidWidth }) => isFluidWidth ? 'inherit' : '4px'};
  box-sizing: border-box;
  border:${({ isSelected }) => isSelected ? '2px solid #000' : '2px solid #CCC'};;
  width: ${({ isFluidWidth, isWide, width }) => {
    if (width) {
      return width;
    }

    if (isFluidWidth) {
      return '100%';
    }

    return isWide ? '30px' : '20px';
  }};
  height: ${({ isFluidHeight }) => isFluidHeight ? '100%' : '20px'};

  :hover {
    cursor: ${({ isButton, pointerCursor }) => isButton || pointerCursor ? 'pointer' : 'default'};
    border:${({ isButton }) => isButton ? '2px solid #555' : '2px solid #CCC'};;
  }
`;

export const InlineBlock = styled.div`
  display: inline-block;
`;

export const Inline = styled.div`
  display: inline-block;
`;

export const Relative = styled.div`
  position: relative;
`;

export const Absolute = styled.div`
  position: absolute;
`;

export const Full = styled.div`
  height: 100%;
  width: 100%;
`;

interface NegativeMarginProps {
  x?: number;
  y?: number;
}

export const NegativeMargin = styled.div<NegativeMarginProps>`
  margin-top: ${({ y }) => y ? `-${y}px` : '0'};
  margin-right: ${({ x }) => x ? `-${x}px` : '0'};
  margin-bottom: ${({ y }) => y ? `-${y}px` : '0'};
  margin-left: ${({ x }) => x ? `-${x}px` : '0'};
`;

interface ColorWrapProps {
  color?: string;
  stroke?: string;
  both?: string;
}

const getColor = (props: ColorWrapProps, key: 'stroke' | 'color') => {
  if (props[key]) {
    return props[key];
  }
  if (props.both) {
    return props.both;
  }
  return 'inherit';
};

export const ColorWrap = styled.div<ColorWrapProps>`
  color: ${(props) => getColor(props, 'color')};
  stroke: ${(props) => getColor(props, 'color')};

  path {
    color:  ${(props) => getColor(props, 'color')};
    stroke:  ${(props) => getColor(props, 'color')};

  }
`;

type Rounding =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 'circle'

interface BorderProps {
  size: number;
  rounding?: Rounding;
  color: string;
  isVisible?: boolean;
}

export const Border = styled.div<BorderProps>`
  box-sizing: border-box;
  border-radius: ${({ rounding }) => {
    if (!rounding) {
      return 0;
    }
    if (rounding === 'circle') {
      return '50%';
    }
    return `${rounding * 4}px`;
  }};
  border: ${({ isVisible, size, color }) => `${size}px solid ${isVisible ? color : 'transparent'}`};
`;
