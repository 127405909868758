import { maxSelector } from '@atoms';
import { application, editor, stitch } from '@hooks';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as Styled from './styled';

interface StitchProps {
  rowIndex: number;
  columnIndex: number;
}

const Stitch: FunctionComponent<StitchProps> = (props) => {
  const { rowIndex, columnIndex } = props;
  const stitchRef = useRef<HTMLDivElement>(null);
  const indices = {
    rowIndex,
    columnIndex,
  };
  const [tempStitch, setTempStitch] = useState<null | boolean>(null);
  const [isDragging, setIsDragging] = editor.useDrag();
  const { squareSize } = useRecoilValue(maxSelector);
  const [currentStitch, toggleStitch] = stitch.useStitch(indices);
  const [currentColor] = application.useStitchColor();
  const [hasLeftAfterStitch, setHasLeftAfterStitch] = useState<boolean>(true);

  const isStitched = (function () {
    if (tempStitch !== null) {
      return tempStitch;
    }
    return currentStitch?.get('isStitched') || false;
  })();

  useEffect(
    () => {
      if (!isDragging.enabled && tempStitch !== null) {
        toggleStitch(tempStitch);
        setTempStitch(null);
      }
    },
    [isDragging, tempStitch],
  );

  useEffect(
    () => {
      if (stitchRef.current) {
        stitchRef.current.addEventListener('dragstart', dragstart);
      }

      return () => {
        if (stitchRef.current) {
          stitchRef.current.removeEventListener('dragstart', dragstart);
        }
      };
    },
    [stitchRef.current, isDragging],
  );

  useEffect(
    () => {
      if (stitchRef.current) {
        stitchRef.current.addEventListener('dragenter', dragenter);
      }

      return () => {
        if (stitchRef.current) {
          stitchRef.current.removeEventListener('dragenter', dragenter);
        }
      };
    },
    [stitchRef.current, isDragging],
  );

  const dragenter = () => {
    setTempStitch(!isDragging.originState);
  };

  const dragstart = (event: DragEvent) => {
    if (stitchRef.current && event.dataTransfer) {
      const clone = stitchRef.current.cloneNode(true) as HTMLDivElement;
      clone.style.transform = 'rotate(45deg)';
      document.body.appendChild(clone);
      event.dataTransfer.setDragImage(clone, 0, 0);
    }
    setTempStitch(!isStitched);
    setIsDragging({
      enabled: true,
      originState: currentStitch?.get('isStitched'),
    });
  };

  const onClick = () => {
    setHasLeftAfterStitch(false);
    toggleStitch();
  };

  const onLeave = () => {
    if (hasLeftAfterStitch === false) {
      setHasLeftAfterStitch(true);
    }
  };

  const stitchLength = Math.sqrt(Math.pow(squareSize, 2) * 2) * 0.95;
  const stitchColor = currentStitch?.get('color') || '#FFF';

  return (
    <Styled.Stitch
      draggable
      onMouseLeave={onLeave}
      ref={stitchRef}
      onClick={onClick}
      stitchColor={stitchColor}
      currentColor={currentColor}
      isStitched={isStitched}
      hasLeftAfterStitch={hasLeftAfterStitch}
      stitchLength={stitchLength}
      squareSize={squareSize}
    />
  );
};

export default Stitch;
