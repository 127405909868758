const getIndexString = (count: number, index: number) => {
  const number = (count - index).toString();
  return number;
};

const utils = {
  getIndexString,
};

export default utils;
