import Sectors from '@sectors';
import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Studio from '../studio';

const Router: FunctionComponent = () => {
  return (
    <Studio>
      <Switch>
        <Route exact path="/" children={<Sectors.Home />} />
        <Route path="/blank" children={<Sectors.Blank />} />
        <Route path="/text" children={<Sectors.TextInput />} />
        <Route path="/studio" children={<Sectors.Editor />} />
      </Switch>
    </Studio>
  );
};

export default Router;
