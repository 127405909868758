import useCanvas from './useCanvas';
import useCanvasCell from './useCanvasCell';
import useCanvasRow from './useCanvasRow';
import useChange from './useChange';
import useClear from './useClear';
import useRowNumber from './useRowNumber';
import useSetBlankCanvas from './useSetBlankCanvas';
import useSetCanvas from './useSetCanvas';
import useSquareSize from './useSquareSize';

const grid = {
  useChange,
  useCanvas,
  useCanvasRow,
  useCanvasCell,
  useClear,
  useRowNumber,
  useSetBlankCanvas,
  useSetCanvas,
  useSquareSize,
};

export default grid;
