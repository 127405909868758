import atoms from '@atoms';
import { createEmptyRow } from '@utils';
import { List } from 'immutable';
import { useSetRecoilState } from 'recoil';
import useStitchColor from '../application/useStitchColor';

const useSetCanvas = () => {
  const setCanvas = useSetRecoilState(atoms.canvas);
  const [color] = useStitchColor();

  const fixFirstRow = (stitches: App.Pixel[]): App.Pixel[] => {
    const hasFirstRow = stitches.filter((stitch) => stitch.y === 0).length > 0;
    if (!hasFirstRow) {
      return stitches.map((stitch) => ({
        ...stitch,
        x: stitch.x - 1,
      }));
    }

    return stitches.map((stitch) => ({
      ...stitch,
      x: stitch.x - 1,
      y: stitch.y + 1,
    }));

  };

  const set = (rawStitches: App.Pixel[]) => {
    const stitches = fixFirstRow(rawStitches);
    const columns = Math.max(...stitches.map(pixel => pixel.x));
    const rowCount = Math.max(...stitches.map(pixel => pixel.y));

    const rows = Array.from({ length: rowCount }, () => createEmptyRow(columns));

    const nextGrid = rows
      .map((row, rowIndex) => {
        const rowPixels = stitches.filter((pixel) => pixel.y === rowIndex);
        const nextCells = row.get('cells').map((cell, columnIndex) => {
          const isStitched = !!rowPixels.find((pixel) => pixel.x === columnIndex);
          if (isStitched) {
            const nextCell = cell.set('isStitched', true).set('color', color);
            return nextCell;
          }
          return cell;
        });
        return row.set('cells', nextCells);
      });

    const gridList = List(nextGrid);
    setCanvas((canvas) => ({
      ...canvas,
      grid: gridList,
    }));
  };

  return [set];
};

export default useSetCanvas;
