import atoms from '@atoms';
import { createEmptyRow } from '@utils';
import makeStitch from '@utils/makeStitch';
import { useRecoilState } from 'recoil';
import useStitchColor from '../../application/useStitchColor';

const useAdd = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);
  const [color] = useStitchColor();

  const buildNextGrid = (options: Change.AddOptions): App.Grid => {
    const { index, axis } = options;
    // const grid = [...canvas.grid];

    if (axis === 'row') {
      const length = canvas.grid.get(0)?.get('cells').size || 0;
      const nextGrid = canvas.grid.splice(index, 0, createEmptyRow(length));
      return nextGrid;
    }

    return canvas.grid.map((row) => {
      const nextCell = makeStitch().set('color', color);
      const nextCells = row.get('cells').splice(index, 0, nextCell);
      const nextRow = row.set('cells', nextCells);
      return nextRow;
    });
  };

  const add = (options: Change.AddOptions) => {
    const nextCanvasGrid = buildNextGrid(options);
    setCanvas({
      ...canvas,
      grid: nextCanvasGrid,
    });
  };

  return [
    add,
  ];
};

export default useAdd;
