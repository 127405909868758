import { Button } from '@components';
import { editor } from '@hooks';
import { Dropdown } from 'gestalt';
import React, { Fragment, FunctionComponent, useRef, useState } from 'react';
import { GrUndo } from 'react-icons/gr';

const History: FunctionComponent = () => {
  const [stats, change] = editor.useHistory();
  const [isLongPress, setIsLongPress] = useState(false);
  const [canDismiss, setCanDismiss] = useState(true);
  const buttonRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);

  const mouseDown = () => {
    timeoutRef.current = setTimeout(
      () => {
        setCanDismiss(false);
        setIsLongPress(true);
      },
      1000,
    );
  };

  const mouseUp = () => {
    setCanDismiss(true);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!isLongPress) {
      change.move(0);
    }
  };

  const onDismissDropdown = () => {
    if (canDismiss) {
      setIsLongPress(false);
    }
  };

  const onClickItem = (payload: App.ClickPayload) => {
    const amount = Number(payload.item.value) - 1;
    change.move(amount * -1);
    setIsLongPress(false);
  };

  const getBackAmounts = (function () {
    const amount = stats.index + 1;
    if (amount > 50) {
      return [5, 10, 50];
    }
    if (amount > 10) {
      return [3, 5, 10];
    }
    const base = [2];
    if (amount > 4) {
      base.push(4);
    }
    if (amount > 6) {
      base.push(6);
    }
    return base;
  })();

  return (
    <Fragment>
      <Button
        ref={buttonRef}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        snug
        isDisabled={stats.size === 0 || stats.index < 0}
        disallowUnselect
      >
        <GrUndo />
      </Button>
      {buttonRef.current && isLongPress && stats.size > 2 &&
        <Dropdown
          anchor={buttonRef.current}
          id="accessibility-example"
          onDismiss={onDismissDropdown}
        >
          {getBackAmounts.map((amount) =>
            <Dropdown.Item
              key={amount}
              onSelect={onClickItem}
              option={{
                label: `Back ${amount} times`,
                value: `${amount}`,
              }}
            />,
          )}
        </Dropdown>
      }
    </Fragment>
  );
};

export default History;
