const hotkeys: ActionMenu.MenuSection = {
  title: 'Hotkeys',
  id: 'hotkeys',
};

const download: ActionMenu.MenuSection = {
  title: 'Download',
  id: 'download',
};

const sections = {
  download,
  hotkeys,
} as const;

export default sections;
