const messages: ActionMenu.ConfirmMessage[] = [
  {
    option: 'clear-canvas',
    heading: 'Are you sure?',
    body: 'Clearing the canvas will clear everything and return to the default state.',
  },
  {
    option: 'clear-stitches',
    heading: 'Are you sure?',
    body: 'Clearing stitches will remove all stitches from the canvas but keep the canvas configuration.',
  },
];

export default messages;
