import atoms from '@atoms';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { UseLoading, UseLoadingResult } from './types';

const flip = (nextState: boolean, currentLoading: App.Loading) => {
  const nextLoading = { ...currentLoading };
  for (const key in nextLoading) {
    const loadingKey = key as keyof App.Loading;
    nextLoading[loadingKey] = nextState;
  }
  return nextLoading;
};


const useLoading: typeof UseLoading = <T extends keyof App.Loading>(question?: T) => {
  const [application, setApplication] = useRecoilState(atoms.application);
  const loading = useRecoilValue(atoms.loading);
  const applicationLoadingRef = useRef(application.loading);

  useEffect(
    () => {
      applicationLoadingRef.current = application.loading;
    },
    [application.loading],
  );

  const setIsLoadingKey = (nextState: boolean) => {
    if (question) {
      switch (question) {
        case 'any':
        case 'all': {
          setApplication((application) => {
            const nextLoading = flip(nextState, loading);
            return {
              ...application,
              loading: nextLoading,
            };
          });
          break;
        }
        default: {
          setApplication((application) => {
            const nextLoading = {
              ...application.loading,
              [question]: nextState,
            };
            return {
              ...application,
              loading: nextLoading,
            };
          });
        }
      }
    }
    return nextState;
  };

  const setLoading = (nextState: Partial<App.Loading>): App.Loading => {
    const nextLoading = {
      ...applicationLoadingRef.current,
      ...nextState,
    };

    setApplication((application) => ({
      ...application,
      loading: nextLoading,
    }));

    const state = {
      ...loading,
      ...nextState,
    };

    return state;
  };

  if (question) {
    const result = loading[question] as App.Loading[T];
    return [
      result,
      setIsLoadingKey,
    ] as unknown as UseLoadingResult<T>;
  }

  return [
    loading,
    setLoading,
  ] as UseLoadingResult<keyof App.Loading>;
};

export default useLoading;
