import { application } from '@hooks';
import { Box, Button, Layer, Modal } from 'gestalt';
import React, { FunctionComponent, useState } from 'react';
import Input from './input';
import Pick from './pick';

interface UploadProps {
  close(): void;
}

const Select: FunctionComponent<UploadProps> = (props) => {
  const { close } = props;
  const [hasChanged, setHasChanged] = useState(false);
  const [color, setColor] = application.useStitchColor();
  const [selectedColor, setSelectedColor] = useState<string>(color);

  const confirm = () => {
    setColor({
      rgb: {
        r: 0,
        g: 0,
        b: 0,
      },
      hex: selectedColor,
    });
    close();
  };

  const selectColor = (color: string) => {
    setSelectedColor(color);
    setHasChanged(true);
  };

  const markInvalid = () => {
    setHasChanged(false);
  };

  return (
    <Layer>
      <Modal
        accessibilityModalLabel="Select Color"
        onDismiss={close}
      >
        <Box
          color="lightGray"
          display="flex"
          padding={8}
          wrap
          width="100%"
          direction="column"
          maxWidth={800}
          alignContent="center"
        >
          <Pick
            selectedColor={selectedColor}
            selectColor={selectColor}
          />
          <Input
            selectedColor={selectedColor}
            currentColor={color}
            markInvalid={markInvalid}
            selectColor={selectColor}
          />
          <Box
            width="100%"
            marginTop={12}
            display="flex"
            justifyContent="between"
          >
            <Button
              text="Cancel"
              color="gray"
              onClick={close}
            />
            <Button
              disabled={!hasChanged}
              text="Change"
              color="blue"
              onClick={confirm}
            />
          </Box>
        </Box>
      </Modal>
    </Layer>
  );
};

export default Select;
