import api from '@api';
import { application, useListeners, useUser } from '@hooks';
import { ColorSchemeProvider } from 'gestalt';
import React, { FunctionComponent, useEffect } from 'react';
import Router from './router';
import * as Styled from './styled';

const Content: FunctionComponent = () => {
  const [listen, hangup] = useListeners();
  const [getLocalStorageValue] = application.useLocalStorage();
  const [, getCurrentUser] = useUser();

  useEffect(
    () => {
      loadCurrentUser();
      listen();

      return () => {
        hangup();
      };
    },
    [],
  );

  const loadCurrentUser = async () => {
    await api.startFirebase();
    getCurrentUser();
  };

  const scheme = getLocalStorageValue('is-dark-mode') ? 'dark' : 'light';

  return (
    <ColorSchemeProvider colorScheme={scheme}>
      <Styled.AppContainer>
        <Router />
      </Styled.AppContainer>
    </ColorSchemeProvider>
  );
};

export default Content;
