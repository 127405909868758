const SAVE_BUILT = false;
const CONTROL_PANEL_BUILT = false;

const IS_DEV = (function () {
  return process.env.NODE_ENV === 'development';
})();

const config = {
  CONTROL_PANEL_BUILT,
  IS_DEV,
  SAVE_BUILT,
};

export default config;
