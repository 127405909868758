import { Button } from '@components';
import { editor } from '@hooks';
import { Box } from 'gestalt';
import React, { FunctionComponent } from 'react';
import { GrRedo } from 'react-icons/gr';
import Undo from './undo';

const History: FunctionComponent = () => {
  const [stats, change] = editor.useHistory();

  const onClickRedo = () => {
    change.move(1);
  };

  return (
    <Box display="flex" alignItems="center" marginEnd={2} marginStart={2}>
      <Undo />
      <Button
        snug
        disallowUnselect
        isDisabled={stats.size - 1 <= stats.index}
        onClick={onClickRedo}
      >
        <GrRedo />
      </Button>
    </Box>
  );
};

export default History;
