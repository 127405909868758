import { optionsAtom } from '@atoms';
import { useRecoilState } from 'recoil';
interface SetOptions {
  (value: Partial<App.Options>): App.Options;
}
type Options = [App.Options, SetOptions];

const useOptions = () => {
  const [options, setRecoilOptions] = useRecoilState(optionsAtom);

  const setOptions = (value: Partial<App.Options>): App.Options => {
    setRecoilOptions({
      ...options,
      ...value,
    });
    return options;
  };

  return [
    options,
    setOptions,
  ] as Options;
};

export default useOptions;
