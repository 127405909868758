import settings from '@settings';
import { Box, ButtonGroup } from 'gestalt';
import React, { FunctionComponent, MutableRefObject } from 'react';
import Menu from './menu';
// import Print from './print';
import Save from './save';

interface MenuProps {
  outputRef: MutableRefObject<HTMLDivElement | null>
}

const Actions: FunctionComponent<MenuProps> = (props) => {
  const { outputRef } = props;

  return (
    <Box
      justifyContent="end"
      alignItems="center"
      display="flex"
      height="100%"
    >
      <ButtonGroup>
        {/* <Print outputRef={outputRef} /> */}
        {settings.SAVE_BUILT && <Save />}
        <Menu />
      </ButtonGroup>
    </Box>
  );
};

export default Actions;
