import settings from '@settings';
import { List, Record } from 'immutable';

export const baseRow = (length: number): App.CanvasRow => {
  const cells = Array.from({ length }, (): App.ImmutableStitch => {
    const cell: App.Stitch = {
      ...settings.DEFAULT_GRID_ITEM,
      id: `${Math.random()}`,
    };
    const Factory = Record(cell);
    return new Factory();
  });

  const row: App.CanvasRowObj = {
    id: `${Math.random()}`,
    cells: List(cells),
  };

  const Factory = Record(row);
  return new Factory();
};


export default baseRow;
