import { Box, Icon, Text } from 'gestalt';
import React, { FunctionComponent } from 'react';
import * as Styled from '../styled';

interface ItemProps {
  button: Change.Button;
}

const Item: FunctionComponent<ItemProps> = (props) => {
  const { button } = props;

  return (
    <Box width="100%" display="flex" justifyContent="between">
      <Text>
        {button.label}
      </Text>
      {button.icon &&
        <Styled.MenuItemIcon
          verticalFlipIcon={button.verticalFlipIcon}
          horizontalFlipIcon={button.horizontalFlipIcon}
        >
          <Icon
            accessibilityLabel={button.label}
            icon={button.icon}
          />
        </Styled.MenuItemIcon>
      }
    </Box>
  );
};

export default Item;
