import { atom } from 'recoil';

const defaultAtom: App.NavigationState = {
  currentModal: '/',
  navigation: '/',
  tabSelection: -1,
};

const navigationAtom = atom({
  key: 'NavigationAtom',
  default: defaultAtom,
});

export default navigationAtom;
