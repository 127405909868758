const Y_AXIS_BUTTONS: Change.Button[] = [
  {
    label: 'Add Row Above',
    type: 'add',
    indexOffset: 0,
  },
  {
    label: 'Add Row Below',
    type: 'add',
    indexOffset: 1,
  },
  {
    label: 'Duplicate Row Above',
    type: 'copy',
    indexOffset: 0,
  },
  {
    label: 'Duplicate Row Below',
    type: 'copy',
    indexOffset: 1,
  },
  {
    label: 'Remove Row Above',
    type: 'remove',
    indexOffset: 0,
  },

  {
    label: 'Remove Row Below',
    type: 'remove',
    indexOffset: 1,
  },
];

const X_AXIS_BUTTONS: Change.Button[] = [
  {
    label: 'Add Column Left',
    type: 'add',
    indexOffset: 0,
  },
  {
    label: 'Add Column Right',
    type: 'add',
    indexOffset: 1,
  },
  {
    label: 'Duplicate Column Above',
    type: 'copy',
    indexOffset: 0,
  },
  {
    label: 'Duplicate Column Right',
    type: 'copy',
    indexOffset: 1,
  },
  {
    label: 'Remove Column Left',
    type: 'remove',
    indexOffset: 0,
  },
  {
    label: 'Remove Column Right',
    type: 'remove',
    indexOffset: 1,
  },
];

const buttons = {
  yAxis: Y_AXIS_BUTTONS,
  xAxis: X_AXIS_BUTTONS,
} as const;

export default buttons;
