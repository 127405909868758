
const style = ' box-shadow: none !important; outline: none;';

interface Prevent {
  <T extends Element>(element: T): void;
}

type UsePreventFocusBorderReturn = [
  Prevent
]
interface UsePreventFocusBorder {
  (): UsePreventFocusBorderReturn
}
const usePreventFocusBorder: UsePreventFocusBorder = () => {
  const applyStyle = <T extends Element>(element: T) => {
    const currentStyle = element.getAttribute('style') || '';
    element.setAttribute('style', `${currentStyle}${style}`);
  };

  const prevent = <T extends Element>(element: T) => {
    applyStyle(element);
    const { children } = element;
    for (let i = 0; i < children.length; i += 1) {
      const child = children.item(i);
      if (child) {
        prevent(child);
      }
    }
  };

  return [
    prevent,
  ];
};

export default usePreventFocusBorder;
