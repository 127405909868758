import { localStorage } from '@utils';


export const DEFAULT_HOTKEYS = {
  registered: [] as string[],
  isEnabled: localStorage.get('hotkeys-enabled', true),
  isInfoHidden: localStorage.get('hide-hotkey-info', false),
} as const;

export const DEFAULT_LOADING: Omit<App.Loading, 'all' | 'any' | 'canvas'> = {
  grid: true,
  window: false,
} as const;
