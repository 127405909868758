import atoms from '@atoms';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import useLocalStorage from './useLocalStorage';

type Callback = (key: string) => void;

type UseHotkeysResult = [
  (key: string, callback: Callback) => void,
  (key: string) => void,
]

interface Registration {
  [key: string]: Callback;
}

const useHotkeys = (): UseHotkeysResult => {
  const [getLocalStorage] = useLocalStorage();
  const [application, setApplication] = useRecoilState(atoms.application);
  const registrations = useRef<Registration>({});
  const hotkeysEnabled = getLocalStorage('hotkeys-enabled');

  useEffect(
    () => {
      // window.addEventListener('keydown', keydownHandler);

      // return () => {
      //   window.removeEventListener('keydown', keydownHandler);
      // };

    },
    [application.hotkeys.registered, hotkeysEnabled],
  );

  const keydownHandler = (event: KeyboardEvent) => {
    // if (!hotkeysEnabled) {
    //   return;
    // }
    // const callback = registrations.current[event.key.toUpperCase()];
    // if (!!callback) {
    //   callback(event.key.toUpperCase());
    //   return false;
    // }
  };

  const registerHotkey = (key: string, callback: (key: string) => void) => {
    // const { hotkeys: { registered } } = application;
    // if (registered.includes(key)) {
    //   return;
    //   // throw new Error(`Hotkey ${key} is already registered`);
    // }

    // const nextHotkeys = [...registered, key];
    // registrations.current[key] = callback;

    // setApplication({
    //   ...application,
    //   hotkeys: {
    //     ...application.hotkeys,
    //     registered: nextHotkeys,
    //   },
    // });

    // listen(key, callback);
  };

  const unregisterHotkey = (key: string) => {

  };

  return [
    registerHotkey,
    unregisterHotkey,
  ];
};

export default useHotkeys;
