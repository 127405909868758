import { Box } from 'gestalt';
import React, { FunctionComponent } from 'react';
import Blank from './blank';
import * as Styled from './styled';
import Text from './text';

const Choice: FunctionComponent = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Styled.ChoiceContainer>
        <Blank />
        <Text />
      </Styled.ChoiceContainer>
    </Box>
  );
};

export default Choice;
