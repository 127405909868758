import Number, { NumberInputRef } from './number';
import Text, { TextInputRef } from './text';

export { default as Number } from './number';
export { default as Text } from './text';

export type NumberRef = NumberInputRef;
export type TextRef = TextInputRef;


const Inputs = {
  Number,
  Text,
};


export default Inputs;
