import { default as Blank } from './blank';
import { default as Editor } from './editor';
import { default as Home } from './home';
import { default as TextInput } from './textInput';

const Sectors = {
  Blank,
  Editor,
  Home,
  TextInput,
};

export default Sectors;
