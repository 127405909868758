import styled from 'styled-components';


export const PositionContainer = styled.div`
  position: relative;
`;

interface PositionHoverProps {
  axis: App.Axis;
  isHovered: boolean;
}

export const PositionHover = styled.div<PositionHoverProps>`
  position: absolute;
  top: ${({ axis }) => axis === 'row' ? '-4px' : '0'};
  right: ${({ axis }) => axis === 'row' ? '0' : '-4px'};
  width: ${({ axis }) => axis === 'row' ? '100%' : '6px'};
  height: ${({ axis }) => axis === 'row' ? '6px' : '100%'};
  border-top-left-radius: ${({ axis }) => axis === 'row' ? '4px' : '0'};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: ${({ axis }) => axis === 'row' ? '0' : '4px'};
  background-color: ${({ isHovered }) => isHovered ? 'rgba(255, 255, 255, 0.4)' : 'transparent'};
  border: ${({ isHovered }) => isHovered ? '1px solid rgba(255, 255, 255, 0.8)' : 'unset'};
  border-right: ${({ axis }) => axis === 'row' ? 'unset' : 'undefined'};
  border-top: ${({ axis }) => axis === 'row' ? 'undefined' : 'unset'};

  &:hover {
    cursor: ${({ isHovered }) => isHovered ? 'pointer' : 'default'};;
  }
`;

interface PositionNumberProps {
  axis: App.Axis;
}

export const PositionNumber = styled.div<PositionNumberProps>`
  text-align: center;
  width: 100%;
  height: 100%;
  vertical-align: top;
  padding: ${({ axis }) => axis === 'row' ? '0 8px 0 0' : '0 auto'};
  color: #fff;
  user-select: none;
  :hover {
    cursor: default;
  }
`;

interface MenuContainerProps {
  axis: App.Axis;
  squareSize: number;
}

export const MenuContainer = styled.div<MenuContainerProps>`
  position: absolute;
  z-index: 1;
  font-size: 14px;
  color: #181818;
  background-color: #D3D3D3;
  left: ${({ squareSize }) => `${squareSize * .1}px`};
  text-align: left;
  top: ${({ squareSize }) => `${squareSize * .7}px`};
  border-radius: 6px;
`;

interface MenuItemProps {
  isLast?: boolean;
  isFirst?: boolean;
}

export const MenuItem = styled.div<MenuItemProps>`
  white-space: nowrap;
  padding: 4px 8px;
  border-top: ${({ isFirst }) => isFirst ? 'none' : '1px solid #A8A8A8'};
  border-bottom: ${({ isLast }) => isLast ? 'none' : '1px solid #A8A8A8'};
  border-bottom-right-radius: ${({ isLast }) => isLast ? '6px' : 0};
  border-bottom-left-radius: ${({ isLast }) => isLast ? '6px' : 0};
  border-top-left-radius: ${({ isFirst }) => isFirst ? '6px' : 0};
  border-top-right-radius: ${({ isFirst }) => isFirst ? '6px' : 0};

  :hover {
    background-color: #787878;
    color: #F5F5F5;
  }
`;

interface HoverButtonProps {
  axis: App.Axis;
  squareSize: number;
}

export const HoverButton = styled.div<HoverButtonProps>`
  position: absolute;
  display: flex;
  z-index: 1;
  align-items: ${({ axis }) => axis === 'row' ? 'flex-end' : 'unset'};;
  /* justify-content: ${({ axis }) => axis === 'row' ? 'flex-end' : 'unset'};; */
  top: ${({ axis, squareSize }) => axis === 'row' ? `-${squareSize * 0.3}px` : `-2px`};
  right: ${({ axis, squareSize }) => axis === 'row' ? '-10px' : `${squareSize * 0.5}px`};
  width: ${({ axis, squareSize }) => axis === 'row' ? `${squareSize}px` : `${squareSize / 2}px`};
  height: ${({ axis, squareSize }) => axis === 'row' ? `${squareSize / 2}px` : `${squareSize}px`};

  :hover {
    cursor: pointer;
  }
`;

export const DropdownContainer = styled.div`
  position:absolute;
  /* right: 250px; */
`;

interface MenuItemIconProps {
  horizontalFlipIcon?: boolean;
  verticalFlipIcon?: boolean;
}

export const MenuItemIcon = styled.div<MenuItemIconProps>`
  position: relative;
  left: 8px;
  transform: ${({ verticalFlipIcon, horizontalFlipIcon }) => verticalFlipIcon || horizontalFlipIcon ? `scale(${horizontalFlipIcon ? '-1' : '1'}, ${verticalFlipIcon ? '-1' : '1'})` : 'unset'};
`;
