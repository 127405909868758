const WIDTH = 1500;
const HEIGHT = 1000;

const DEFAULT_WINDOW_SIZE = {
  width: WIDTH,
  height: HEIGHT,
};

const DEFAULT_GRID_ITEM: Omit<App.Stitch, 'id'> = {
  isStitched: false,
  color: '#FFFFFF',
};

const DEFAULT_IS_DRAGGING: App.IsDragging = {
  enabled: false,
  originState: false,
};

const GOOGLE_API_KEY = 'AIzaSyAkqdx073MGW7SHEsqHN0rGkl4eHrBni8w';

const constants = {
  GOOGLE_API_KEY,
  DEFAULT_GRID_ITEM,
  DEFAULT_IS_DRAGGING,
  DEFAULT_WINDOW_SIZE,
};

export default constants;
