import styled from 'styled-components';

interface PreventFocusBorderProps {
  allowBorder?: boolean;
}

const initial = `medium none invert`;

const PreventFocusBorder = styled.div<PreventFocusBorderProps>`
  & * {
    box-shadow: none !important;
    outline: ${({ allowBorder }) => allowBorder ? initial : 'none'}
  }
`;

PreventFocusBorder.displayName = 'DIV';


export default PreventFocusBorder;
