import { Inputs } from '@components';
import { useOptionsSingle } from '@hooks';
import { Box } from 'gestalt';
import React, { Fragment, FunctionComponent } from 'react';
import Font from './font';

const Details: FunctionComponent = () => {
  const [resolution, setResolution] = useOptionsSingle('resolution');

  return (
    <Fragment>
      <Box maxWidth={250} alignSelf="start">
        <Font />
      </Box>
      <Box maxWidth={100} alignSelf="start">
        <Inputs.Number
          delay={1000}
          value={resolution}
          label="Size"
          onValidInput={setResolution}
        />
      </Box>
    </Fragment>
  );
};

export default Details;
