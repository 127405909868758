import { Box } from 'gestalt';
import React, { FunctionComponent } from 'react';

const html = `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2683234040651321"
crossorigin="anonymous"></script>
<!-- Studio Footer -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2683234040651321"
data-ad-slot="7463880565"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>
`;

const Footer: FunctionComponent = () => {
  return (
    <Box
      color="darkWash"
      alignSelf="end"
      height="10vh"
      width="100%"
      marginTop={1}
      rounding={3}
      paddingY={3}
      display="flex"
      justifyContent="center"
    >
      <div dangerouslySetInnerHTML={{ __html: html }}>
      </div>
    </Box>
  );
};

export default Footer;
