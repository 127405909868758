import { Inputs, NumberRef, Setup } from '@components';
import { application, grid } from '@hooks';
import { Box } from 'gestalt';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

const Blank: FunctionComponent = () => {
  const [setBlankCanvas] = grid.useSetBlankCanvas();
  const history = useHistory();
  const [, setHasStarted] = application.useHasStarted();
  const [columnsAmount, setColumnsAmount] = useState<number | null>(null);
  const [rowsAmount, setRowsAmount] = useState<number | null>(null);
  const rowsComponentRef = useRef<NumberRef>(null);

  const isReady = rowsAmount !== null
    && rowsAmount !== 0
    && columnsAmount !== null
    && columnsAmount !== 0;

  useEffect(
    () => {
      if (rowsComponentRef.current) {
        rowsComponentRef.current.focus();
      }
    },
    [rowsComponentRef],
  );

  useEffect(
    () => {
      window.addEventListener('keypress', keypress);

      return () => {
        window.removeEventListener('keypress', keypress);
      };
    },
    [rowsAmount, columnsAmount],
  );


  const buildBlankCanvas = () => {
    if (rowsAmount !== null && columnsAmount !== null) {
      setHasStarted(true);
      history.push('/studio');
      setBlankCanvas(rowsAmount, columnsAmount);
    }
  };

  const keypress = (event: KeyboardEvent) => {
    if (event.code === 'Enter' && isReady) {
      buildBlankCanvas();
    }
  };

  const setRows = (amount: number) => {
    setRowsAmount(amount);
  };

  const setColumns = (amount: number) => {
    setColumnsAmount(amount);
  };

  return (
    <Setup
      submitOptions={{
        label: 'Create Canvas',
        isDisabled: !isReady,
        onClick: buildBlankCanvas,
      }}
    >
      <Box maxWidth={100}>
        <Inputs.Number
          ref={rowsComponentRef}
          label="Rows"
          preventZero
          max={50}
          onValidInput={setRows}
        />
      </Box>
      <Box maxWidth={100}>
        <Inputs.Number
          label="Columns"
          preventZero
          max={50}
          onValidInput={setColumns}
        />
      </Box>
    </Setup >
  );
};

export default Blank;
