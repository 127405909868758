import { Loading } from '@components';
import { editor, grid, useOptionsSingle, useSize, useStitchContainer } from '@hooks';
import { debounce } from '@utils';
import { Box, BoxProps } from 'gestalt';
import React, { forwardRef, Fragment, useCallback, useEffect, useRef } from 'react';
import ColumnNumbers from './columnNumbers';
import Row from './row';
import * as Styled from './styled';

const Canvas = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const [canvas] = grid.useCanvas();
  const [, parseText] = editor.useParseText();
  const [container, setContainer] = useStitchContainer();
  const [text] = useOptionsSingle('text');
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = editor.useDrag();
  const [size] = useSize();
  const { isMobile } = size;

  useEffect(
    () => {
      parseText(text);
    },
    [text],
  );

  const getSize = useCallback(
    () => {
      const { current } = containerRef;
      if (current) {
        const { offsetHeight: height, offsetWidth: width } = current;
        const container = {
          width,
          height,
        };
        setContainer(container);
        // setLoading(false);
      }
    },
    [],
  );

  useEffect(
    () => {
      getSize();
    },
    [],
  );

  useEffect(
    () => {
      if (containerRef.current) {
        containerRef.current.addEventListener('mouseleave', mouseLeave);
      }

      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener('mouseleave', mouseLeave);
        }
      };
    },
    [containerRef.current, isDragging],
  );

  const mouseLeave = () => {
    setIsDragging({
      enabled: false,
    });
  };

  useEffect(
    () => {
      const debouncedGetSize = debounce(getSize, 100);
      window.addEventListener('resize', debouncedGetSize);

      return () => {
        window.removeEventListener('resize', debouncedGetSize);
      };
    },
    [],
  );

  const containerProps: BoxProps = {
    display: 'flex',
    width: '100%',
    height: '100%',
    color: 'navy',
    borderStyle: 'lg',
    rounding: isMobile ? 4 : 8,
    padding: isMobile ? 1 : 4,
    alignItems: 'center',
    justifyContent: 'center',
  };

  const isLoading = container.height === 0;

  return (
    <Box ref={containerRef} {...containerProps}>
      <Styled.Container ref={ref}>
        <Fragment>
          {isLoading && <Loading />}
          <Styled.CanvasContainer isLoading={isLoading}>
            {canvas.map((row, i) =>
              <Row rowIndex={i} key={row} />,
            )}
          </Styled.CanvasContainer>
          <ColumnNumbers />
        </Fragment>
      </Styled.Container>
    </Box>
  );
});

export default Canvas;
