import { client } from '@utils';
import useLocalStorage from './useLocalStorage';

type UseShowHotkeyInfoResult = [boolean, (base: string) => string | null];

interface UseShowHotkeyInfo {
  (): UseShowHotkeyInfoResult;
}

const useShowHotkeyInfo: UseShowHotkeyInfo = () => {
  const [getLocalStorageValue] = useLocalStorage();

  const hotkeysEnabled = getLocalStorageValue('hotkeys-enabled');
  const hotkeyInfoEnabled = getLocalStorageValue('hide-hotkey-info');

  const createLabel = (base: string): string | null => client.metaKey ? ` [${base}]` : null;

  const show = !!hotkeysEnabled && !!hotkeyInfoEnabled && !!client.metaKey;

  return [
    show,
    createLabel,
  ];
};


export default useShowHotkeyInfo;
