import { canvasAtom } from '@atoms';
import { useRecoilState } from 'recoil';

interface UseStitchContainer {
  (): [container: Utils.Size, setContainer: (size: Partial<Utils.Size>) => void];
}

const useStitchContainer: UseStitchContainer = () => {
  const [canvas, setCanvas] = useRecoilState(canvasAtom);

  const setContainer = (size: Partial<Utils.Size>) => {
    setCanvas((state) => {
      const container = {
        ...state.container,
        ...size,
      };
      return {
        ...state,
        container,
      };
    });
  };

  return [canvas.container, setContainer];
};

export default useStitchContainer;
