import { application, editor } from '@hooks';
import { Box, Dropdown, IconButton } from 'gestalt';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Confirm from '../confirm';
import buildMenu from './buildMenu';

const Menu: FunctionComponent = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [getLocalStorageValue, setLocalStorageValue] = application.useLocalStorage();
  const [downloadCSV] = editor.useCSV();
  const [registerHotkey, unregisterHotkey] = application.useHotkeys();
  const [waitingChange, setWaitingChange] = useState<ActionMenu.ConfirmMessage | null>(null);

  const hotkey = 'M';

  useEffect(
    () => {
      registerHotkey(hotkey, toggleOpenMenu);

      return () => {
        unregisterHotkey(hotkey);
      };
    },
    [],
  );

  const iconRef = useRef<HTMLButtonElement>(null);

  const toggleOpenMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const onDismissDropdown = () => {
    setMenuIsOpen(false);
  };

  const onClickItem = (data: App.ClickPayload) => {
    const payload = data as App.ClickPayload<ActionMenu.Option>;
    setMenuIsOpen(false);
    const { value, confirmMessage } = payload.item;

    if (confirmMessage) {
      setWaitingChange(confirmMessage);
      return;
    }

    switch (value) {
      case 'hotkeys-enabled': {
        setLocalStorageValue('hotkeys-enabled', !getLocalStorageValue('hotkeys-enabled'));
        break;
      }
      case 'hide-hotkey-info': {
        setLocalStorageValue('hide-hotkey-info', !getLocalStorageValue('hide-hotkey-info'));
        break;
      }
      case 'is-dark-mode': {
        setLocalStorageValue('is-dark-mode', !getLocalStorageValue('is-dark-mode'));
        break;
      }
      case 'download-csv': {
        downloadCSV();
        break;
      }
    }

    closeConfirm();
  };

  const menuOptions = buildMenu({
    getLocalStorageValue,
  });

  const closeConfirm = () => {
    setWaitingChange(null);
  };

  return (
    <Box>
      <IconButton
        accessibilityControls="accessibility-settings"
        accessibilityExpanded={menuIsOpen}
        accessibilityHaspopup
        accessibilityLabel="Settings"
        bgColor="gray"
        icon="menu"
        iconColor="white"
        onClick={toggleOpenMenu}
        ref={iconRef}
        selected={menuIsOpen}
        size="lg"
      />
      {iconRef.current && menuIsOpen &&
        <Dropdown
          anchor={iconRef.current}
          id="accessibility-example"
          onDismiss={onDismissDropdown}
        >
          {menuOptions.map((option) =>
            <Dropdown.Section
              label={option.sectionTitle === 'NO_SECTION' ? '' : option.sectionTitle}
              key={option.sectionTitle}
            >
              {option.options.map((sectionOption) =>
                <Dropdown.Item
                  key={sectionOption.value}
                  onSelect={onClickItem}
                  option={sectionOption}
                />,
              )}
            </Dropdown.Section>,

          )}
        </Dropdown>
      }
      {waitingChange &&
        <Confirm
          message={waitingChange}
          close={closeConfirm}
        />
      }
    </Box>
  );
};

export default Menu;
