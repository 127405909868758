import { maxSelector } from '@atoms';
import { grid } from '@hooks';
import React, { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import RowNumbers from './rowNumbers';
import Square from './square';
import * as Styled from './styled';

interface RowProps {
  rowIndex: number;
}

const Row: FunctionComponent<RowProps> = (props) => {
  const { rowIndex } = props;
  const [row] = grid.useCanvasRow(rowIndex);
  const { squareSize } = useRecoilValue(maxSelector);

  return (
    <Styled.RowContainer>
      <Styled.Row rowHeight={squareSize}>
        <RowNumbers rowIndex={rowIndex} />
        {row.get('cells').map((cell, i) =>
          <Square
            key={cell.get('id')}
            rowIndex={rowIndex}
            columnIndex={i}
          />,
        )}
      </Styled.Row>
    </Styled.RowContainer >
  );
};

export default Row;
