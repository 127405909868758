import { default as useHasStarted } from './useHasStarted';
import { default as useHotkeys } from './useHotkeys';
import { default as useLoading } from './useLoading';
import { default as useLocalStorage } from './useLocalStorage';
import { default as useShowHotkeyInfo } from './useShowHotkeyInfo';
import { default as useStartOption } from './useStartOption';
import { default as useStitchColor } from './useStitchColor';

const application = {
  useHasStarted,
  useHotkeys,
  useLoading,
  useLocalStorage,
  useShowHotkeyInfo,
  useStartOption,
  useStitchColor,
};

export default application;
