import atoms from '@atoms';
import { useRecoilState } from 'recoil';

interface UpdateControlState {
  <T extends keyof Atoms.ControlState>(key: T, value: Atoms.ControlState[T]): Atoms.ControlState
}
type UseControlStateResult = [Atoms.ControlState, UpdateControlState];

interface UseControlState {
  (): UseControlStateResult
}

const useControlState: UseControlState = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);

  const update = <T extends keyof Atoms.ControlState>(key: T, value: Atoms.ControlState[T]) => {
    const nextControlState = {
      ...canvas.controlState,
      [key]: value,
    };
    setCanvas((prev) => ({
      ...prev,
      controlState: nextControlState,
    }));
    return nextControlState;
  };

  return [canvas.controlState, update];
};

export default useControlState;
