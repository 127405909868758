import { nextTick } from '@utils';
import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyAkqdx073MGW7SHEsqHN0rGkl4eHrBni8w",
  authDomain: "needlepoint-5f2ee.firebaseapp.com",
  databaseURL: "https://needlepoint-5f2ee-default-rtdb.firebaseio.com",
  projectId: "needlepoint-5f2ee",
  storageBucket: "needlepoint-5f2ee.appspot.com",
  messagingSenderId: "423889923704",
  appId: "1:423889923704:web:14af8bc982bb15a7a6c78d",
  measurementId: "G-X7YHEQ48SD",
};

interface FirebaseApp {
  database: firebase.database.Database;
  user: firebase.User | null;
}

const start = async (): Promise<FirebaseApp> => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }

  await nextTick(1000);

  return {
    database: firebase.database(),
    user: firebase.auth().currentUser,
  };
};

export default start;
