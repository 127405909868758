import atoms from '@atoms';
import { List, Record } from 'immutable';
import { useRecoilValue } from 'recoil';

const useCanvasRow = (rowIndex: number): [App.CanvasRow] => {
  const canvas = useRecoilValue(atoms.canvas);

  const blank = {
    id: `${Math.random()}`,
    cells: List([]),
  };

  const Factory = Record(blank);
  const rowItems = canvas.grid.get(rowIndex) || new Factory();

  return [
    rowItems,
  ];
};

export default useCanvasRow;
