import { atom } from 'recoil';

const defaultAtom: Atoms.User = {
  currentUser: null,
  isLoggedIn: false,
  isLoading: true,
};

const userAtom = atom({
  key: 'User',
  default: defaultAtom,
});

export default userAtom;
