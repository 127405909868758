import { Box, BoxProps } from 'gestalt';
import React, { FunctionComponent } from 'react';
import * as Styled from './styled';

interface PointerBoxProps extends BoxProps {
  isHoverOutline?: boolean;
}

const PointerBox: FunctionComponent<PointerBoxProps> = (props) => {
  const { isHoverOutline, ...rest } = props;
  return (
    <Styled.PointerBox>
      <Box {...rest} />
    </Styled.PointerBox>
  );
};

export default PointerBox;
