import { Tag } from "gestalt";
import React, { FunctionComponent } from 'react';
import * as Styled from '../styled';

interface NoteProps {
  message: string;
}

const Note: FunctionComponent<NoteProps> = (props) => {
  const { message } = props;

  return (
    <Styled.NoteContainer>
      <Tag text={message} disabled />
    </Styled.NoteContainer>
  );
};

export default Note;
