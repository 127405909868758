import useClear from './useClear';
import useHasContent from './useHasContent';
import useStitch from './useStitch';

const stitch = {
  useClear,
  useHasContent,
  useStitch,
};

export default stitch;
