import { Box, TapArea } from 'gestalt';
import React, { forwardRef, MouseEventHandler, PropsWithChildren, useEffect, useRef, useState } from 'react';
import UI from '../ui';

interface ButtonProps {
  onClick?(): void;
  snug?: boolean;
  isSelected?: boolean;
  disallowUnselect?: boolean;
  isDisabled?: boolean;
  onMouseDown?: MouseEventHandler<HTMLDivElement>
  onMouseUp?: MouseEventHandler<HTMLDivElement>
}

const Button = forwardRef<HTMLDivElement, PropsWithChildren<ButtonProps>>((props, ref) => {
  const {
    children,
    disallowUnselect,
    isDisabled,
    isSelected,
    onClick,
    onMouseDown,
    onMouseUp,
    snug,
  } = props;
  const boxRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(
    () => {
      if (boxRef.current) {
        boxRef.current.addEventListener('mouseenter', mouseEnter);
        boxRef.current.addEventListener('mouseleave', mouseLeave);
      }

      return () => {
        if (boxRef.current) {
          boxRef.current.removeEventListener('mouseenter', mouseEnter);
          boxRef.current.removeEventListener('mouseleave', mouseLeave);
        }
      };
    },
    [boxRef.current],
  );

  const mouseEnter = () => {
    setIsHovered(true);
  };

  const mouseLeave = () => {
    setIsHovered(false);
  };

  const color = () => {
    if (isDisabled) {
      return 'lightWash';

    }

    if (isHovered) {
      if (disallowUnselect && isSelected) {
        return 'white';
      }
      return 'lightGray';
    }

    if (isSelected) {
      return 'white';
    }

    return 'lightWash';
  };

  const disabled = isDisabled || (isSelected && disallowUnselect);

  return (
    <Box
      ref={ref}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      marginStart={snug ? 1 : 2}
      marginEnd={snug ? 1 : 2}
      rounding={8}
      alignItems="center"
      height={snug ? 'min-content' : '100%'}
      width={snug ? 'min-content' : '170px'}
      color={color()}
    >
      <UI.Border
        size={2}
        isVisible={!isDisabled}
        color="#CCC"
        rounding={8}>
        <TapArea
          disabled={disabled}
          fullHeight
          rounding={8}
          onTap={onClick}
        >
          <Box
            rounding="circle"
            ref={boxRef}
            alignItems="center"
            direction="column"
            display="flex"
            height={snug ? undefined : '100%'}
            justifyContent="center"
            padding={3}
          >
            <UI.ColorWrap
              both={isDisabled ? '#fff' : '#000'}
            >
              {children}
            </UI.ColorWrap>
          </Box>
        </TapArea>
      </UI.Border>
    </Box>
  );
});

export default Button;
