import application from './application';
import canvas from './canvas';
import editor from './editor';
import loading from './loading';
import stitch from './stitch';

export { default as canvasAtom } from './canvas';
export { default as dialsAtom } from './dials';
export { default as maxSelector } from './max';
export { default as navigationAtom } from './navigation';
export { default as optionsAtom } from './options';
export { default as userAtom } from './user';
export { default as windowSizeAtom } from './windowSize';

const atoms = {
  application,
  editor,
  canvas,
  loading,
  stitch,
};

export default atoms;
