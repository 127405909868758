import styled from 'styled-components';

export const ComboBoxContainer = styled.div`
  > div {
    min-width: unset !important;
    width: 100%;

    :focus {
      outline:0;
    }
  } 
`;

export const RandomLink = styled.span`
  font-size: 60%;
  position: absolute;
`;
