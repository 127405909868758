import atoms from '@atoms';
import settings from '@settings';
import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useSize from './useSize';

type UseListenReturn = [
  () => void,
  () => void,
]

interface UseListeners {
  (): UseListenReturn
}

const useListeners: UseListeners = () => {
  const [, setSize] = useSize();
  const { mouseOption } = useRecoilValue(atoms.canvas);
  const pressedRef = useRef<null | string>(null);
  const setEditor = useSetRecoilState(atoms.editor);

  useEffect(
    () => {
      listen();

      return hangup;
    },
    [pressedRef.current, mouseOption],
  );

  const keydown = (event: KeyboardEvent) => {
    const isSKey = event.key === 's' || event.key === 'S';
    if (pressedRef.current === null && isSKey && mouseOption === 'hover') {
      setEditor((editor) => ({
        ...editor,
        hotkeyPressed: 'S',
      }));
      pressedRef.current = 'S';
    }
  };

  const keyup = (event: KeyboardEvent) => {
    const isSKey = event.key === 's' || event.key === 'S';
    if (pressedRef.current && isSKey) {
      pressedRef.current = null;
      setEditor((editor) => ({
        ...editor,
        hotkeyPressed: null,
      }));
    }
  };

  const onResize = () => {
    const { innerHeight: height, innerWidth: width } = window || {};
    setSize({
      height,
      width,
    });
  };

  const mouseup = () => {
    setEditor((editor) => ({
      ...editor,
      isDragging: settings.DEFAULT_IS_DRAGGING,
    }));
  };

  const listen = () => {
    window.addEventListener('resize', onResize);
    window.addEventListener('keydown', keydown);
    window.addEventListener('keyup', keyup);
    window.addEventListener('mouseup', mouseup);
  };

  const hangup = () => {
    window.removeEventListener('keydown', keydown);
    window.removeEventListener('keyup', keyup);
    window.removeEventListener('resize', onResize);
    window.removeEventListener('mouseup', mouseup);
  };


  return [
    listen,
    hangup,
  ];
};

export default useListeners;
