import { windowSizeAtom } from '@atoms';
import { sizes } from '@utils';
import { useRecoilState } from 'recoil';

interface WindowSize {
  height: number;
  width: number;
}

interface SetWindowSize {
  (size: WindowSize): void;
}

interface SetWindowSizePart {
  (size: Partial<WindowSize>): void;
}

type UseSizeResult = [Atoms.WindowSize, SetWindowSize, SetWindowSizePart];

interface UseSizeHook {
  (): UseSizeResult;
}

const useSize: UseSizeHook = () => {
  const [windowSize, setWindowSizeAtom] = useRecoilState(windowSizeAtom);

  const setWindowSize = (size: WindowSize) => {
    setWindowSizeAtom(() => ({
      ...size,
      isMobile: sizes.findIfMobile(size.width, size.height),
    }));
  };

  const setWindowSizePart = (size: Partial<WindowSize>) => {
    setWindowSize({
      ...windowSize,
      ...size,
    });
  };

  return [
    windowSize,
    setWindowSize,
    setWindowSizePart,
  ];
};

export default useSize;
