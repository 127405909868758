import { maxSelector } from '@atoms';
import Position from '@sectors/editor/canvas/position';
import React, { Fragment, FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import Square from './square';
import * as Styled from './styled';
import utils from './utils';

interface RowNumbersProps {
  rowIndex: number;
}

const RowNumbers: FunctionComponent<RowNumbersProps> = (props) => {
  const { rowIndex } = props;
  const { squareSize, rowCount } = useRecoilValue(maxSelector);

  return (
    <Fragment>
      <Styled.RowPosition squareHeight={squareSize} squareWidth={squareSize}>
        <Position
          isLast={rowIndex === 0}
          index={rowIndex}
          position={utils.getIndexString(rowCount, rowIndex)}
          axis="row"
        />
      </Styled.RowPosition>
      <Square
        rowIndex={rowIndex}
        columnIndex={-1}
      />
    </Fragment>
  );
};

export default RowNumbers;
