import { Box, Pulsar } from 'gestalt';
import React, { FunctionComponent } from 'react';

const Loading: FunctionComponent = () => {
  return (
    <Box display="flex" direction="column">
      <Pulsar />
    </Box>
  );
};

export default Loading;
