import { application } from '@hooks';
import { Heading } from 'gestalt';
import React, { FunctionComponent, useEffect } from 'react';
import Tap from './tap';

const TextInput: FunctionComponent = () => {
  const [showHotkeyInfo, createLabel] = application.useShowHotkeyInfo();
  const [, setStartOption] = application.useStartOption();
  const [registerHotkey, unregisterHotkey] = application.useHotkeys();

  const hotkey = 'T';

  const onClick = () => {
    setStartOption('text');
  };

  useEffect(
    () => {
      registerHotkey(hotkey, onClick);

      return () => {
        unregisterHotkey(hotkey);
      };
    },
    [],
  );

  const buttonText = `Text${showHotkeyInfo ? createLabel(hotkey) : ''}`;

  return (
    <Tap
      onTap={onClick}
      onClickDataLayer="setup-text"
    >
      <Heading
        size="lg"
        color="white"
      >
        {buttonText}
      </Heading>
    </Tap>
  );
};

export default TextInput;
