import firebase from 'firebase';

const userLogout = async (): Promise<null> => {
  try {
    await firebase.auth().signOut();
    return null;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    throw new Error(`${errorCode}: ${errorMessage}`);
  }
};

export default userLogout;
