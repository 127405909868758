import { sizes } from '@utils';
import { atom } from 'recoil';

const { innerHeight: height, innerWidth: width } = window || {};

const defaultAtom: Atoms.WindowSize = {
  width: width || 1000,
  height: height || 1000,
  isMobile: sizes.findIfMobile(width, height),
};

const windowSizeAtom = atom({
  key: 'WindowSizeAtom',
  default: defaultAtom,
});

export default windowSizeAtom;
