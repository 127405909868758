import { maxSelector } from '@atoms';
import settings from '@settings';
import React, { FunctionComponent } from 'react';
import { useRecoilValue } from 'recoil';
import Stitch from './stitch';
import * as Styled from './styled';

interface SquareProps {
  rowIndex: number;
  columnIndex: number;
}

const Square: FunctionComponent<SquareProps> = (props) => {
  const { rowIndex, columnIndex } = props;
  const { squareSize } = useRecoilValue(maxSelector);

  const rowLimit = settings.IS_DEV ? -1 : 0;

  return (
    <Styled.Square
      data-square
      squareWidth={squareSize}
      squareHeight={squareSize}
    >
      {columnIndex > -1 && rowIndex > rowLimit &&
        <Stitch
          rowIndex={rowIndex}
          columnIndex={columnIndex}
        />
      }
    </Styled.Square>
  );
};

export default Square;
