import useControlState from './useControlState';
import useCSV from './useCSV';
import useDrag from './useDrag';
import useHistory from './useHistory';
import useIsStitchHotkeyPressed from './useIsStitchHotkeyPressed';
import useMouseOption from './useMouseOption';
import useParseText from './useParseText';

const editor = {
  useControlState,
  useCSV,
  useDrag,
  useHistory,
  useIsStitchHotkeyPressed,
  useMouseOption,
  useParseText,
};

export default editor;
