import { util } from '@hooks';
import { debounce } from '@utils';
import { TextField, TextFieldChange } from 'gestalt';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import * as Styled from './styled';

interface TextProps {
  size?: 'md' | 'lg';
  label?: string;
  placeholder?: string;
  value?: any;
  onValidInput(input: string): void;
  delay?: number;
}

export interface TextInputRef {
  focus(): void;
}

const Text = forwardRef<TextInputRef, TextProps>((props, ref) => {
  const { onValidInput, size, value, placeholder, label, delay } = props;
  const idRef = useRef(label || `${Math.random()}`);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string | undefined>(value);
  const debounceChangeRef = useRef(debounce(onValidInput, delay || 1000));
  const [prevent] = util.usePreventFocusBorder();
  useImperativeHandle(ref, () => ({
    focus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  useEffect(
    () => {
      debounceChangeRef.current = debounce(onValidInput, delay || 1000);
    },
    [delay, onValidInput],
  );

  useEffect(
    () => {
      if (inputRef.current) {
        prevent(inputRef.current);
      }
    },
    [inputRef.current],
  );

  const onChange = (change: TextFieldChange) => {
    const input = change.value;
    setInputValue(input);

    if (typeof delay === 'undefined') {
      onValidInput(input);
    }

    debounceChangeRef.current(input);
  };

  return (
    <Styled.Container>
      <TextField
        ref={inputRef}
        autoComplete="off"
        id={idRef.current}
        size={size}
        placeholder={placeholder}
        onChange={onChange}
        label={label}
        value={inputValue}
        type="text"
      />
    </Styled.Container>
  );
});

export default Text;
