import { util } from '@hooks';
import { debounce } from '@utils';
import { TextField, TextFieldChange } from 'gestalt';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import * as Styled from './styled';

interface NumberInputProps {
  label: string;
  value?: number;
  delay?: number;
  onValidInput(input: number): void;
  preventZero?: boolean;
  max?: number;
}

export interface NumberInputRef {
  focus(): void;
}

const NumberInput = forwardRef<NumberInputRef, NumberInputProps>((props, ref) => {
  const { delay, onValidInput, value, label, preventZero, max } = props;
  const [inputValue, setInputValue] = useState<number | undefined>(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceChangeRef = useRef(debounce(onValidInput, delay || 1000));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [prevent] = util.usePreventFocusBorder();

  useImperativeHandle(ref, () => ({
    focus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  useEffect(
    () => {
      if (inputRef.current) {
        prevent(inputRef.current);
      }
    },
    [inputRef.current],
  );

  useEffect(
    () => {
      debounceChangeRef.current = debounce(onValidInput, delay || 1000);
    },
    [delay, onValidInput],
  );


  const onChange = (change: TextFieldChange) => {
    const input = Number(change.value);
    if (preventZero && input === 0) {
      setErrorMessage('Cannot be zero');
      return;
    }

    if (typeof max !== 'undefined' && input > max) {
      setErrorMessage(`Cannot be greater than ${max}`);
      return;
    }

    if (errorMessage !== null) {
      setErrorMessage(null);
    }

    setInputValue(input);
    if (typeof delay === 'undefined') {
      onValidInput(input);
    }

    debounceChangeRef.current(input);
  };

  return (
    <Styled.Container>
      <TextField
        ref={inputRef}
        id={label}
        onChange={onChange}
        label={label}
        value={`${inputValue}`}
        errorMessage={errorMessage}
        type="number"
      />
    </Styled.Container>
  );
});

export default NumberInput;
