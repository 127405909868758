import atoms from '@atoms';
import { useRecoilState } from 'recoil';

interface UpdateMouseOption {
  (option: Atoms.MouseOption): Atoms.MouseOption
}
type UseMouseOptionResult = [Atoms.MouseOption, UpdateMouseOption];

interface UseMouseOption {
  (): UseMouseOptionResult
}

const useMouseOption: UseMouseOption = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);

  const update = (option: Atoms.MouseOption): Atoms.MouseOption => {
    setCanvas((prev) => ({
      ...prev,
      mouseOption: option,
    }));
    return option;
  };

  return [canvas.mouseOption, update];
};

export default useMouseOption;
