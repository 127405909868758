import atoms from '@atoms';
import { client, createEmptyRow } from '@utils';
import type { Location } from 'history';
import { List, Record } from 'immutable';
import LZString from 'lz-string';
import { useLocation } from 'react-router';
import { useRecoilState } from 'recoil';

type UseCSVResult = [() => void, () => void, (search: string) => void];

interface UseCSV {
  (): UseCSVResult
}

interface FileData {
  value: string;
  ext: string;
}

const buildCSV = (grid: App.Grid) => grid
  .map((row, rowIndex) => {
    const stitched = row.get('cells').map((cell, columnIndex) => {
      const isStitched = cell.get('isStitched');
      if (isStitched) {
        return `${columnIndex},${cell.get('color')}`;
      }
      return 'NOT_STITCHED';
    });
    return `${rowIndex},${stitched.filter(cell => cell !== 'NOT_STITCHED').join(',')}`;
  })
  .filter((row, rowIndex) => row !== `${rowIndex},`)
  .join('\n');

const buildUncompressed = (compressed: string): App.Grid => {
  const lines = compressed
    .split('R')
    .filter(line => line.length > 0)
    .map((line) => {
      const cellsString = line.split(',');
      const lineNumber = cellsString.shift();

      if (!lineNumber) {
        throw new Error('Invalid line number');
      }

      const cells = cellsString.map((cell) => {
        const content = cell.split('#');
        return {
          color: `#${content[1]}`,
          column: Number(content[0]),
        };
      });
      return {
        cells,
        lineNumber: Number(lineNumber),
      };
    });
  const lineCount = Math.max(...lines.map((line) => Number(line.lineNumber)));
  const columnCount = Math.max(...lines.map((line) => Math.max(...line.cells.map((cell) => cell.column))));
  const blankRow = createEmptyRow(columnCount);

  const result = Array.from({ length: lineCount })
    .map((_, index): App.CanvasRow => {
      const savedLine = lines.find((line) => line.lineNumber === index);

      if (!savedLine) {
        return blankRow;
      }

      const cells = blankRow.get('cells').map((cell) => {
        const savedCell = savedLine.cells.find((cell) => cell.column === cell.column);
        if (!savedCell) {
          return cell;
        }
        const cellBase = {
          id: `${Math.random()}`,
          isStitched: true,
          color: savedCell.color,
        };
        const CellFactory = Record(cellBase);
        return new CellFactory();
      });

      const base = {
        id: `${Math.random()}`,
        cells,
      };
      const Factory = Record(base);
      return new Factory();
    });

  return List(result);
};

const buildCompressed = (str: string) => str.split('\n').map((line) => {
  const temp = line.replace(/,#/, '#');
  return `R${temp}`;
}).join('');

const downloadData = (data: FileData) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.value));
  element.setAttribute('download', `needlepoint.${data.ext}`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const getWinOrOsxShortcut = (url: string) =>
  `[InternetShortcut]
URL=${url}
`;

const getUnixShortcut = (url: string) =>
  `[Desktop Entry]
Encoding=UTF-8
URL=${url}
Type=Link
Icon=text-html
`;

const createURLFile = (url: string) => {
  const { os } = client;

  if (os == 'macintosh' || os == 'windows') {
    return {
      value: getWinOrOsxShortcut(url),
      ext: 'url',
    };
  } else {
    return {
      value: getUnixShortcut(url),
      ext: 'desktop',
    };
  }
};

const getUrl = (href: string, routeLocation: Location) => {
  const { search } = routeLocation;
  const base = href.replace(/[\?]$/, '');
  const searchRegex = new RegExp(`${search}$`);
  return base.replace(searchRegex, '');
};

const useCSV: UseCSV = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);
  const location = useLocation();

  const download = () => {
    const { grid } = canvas;
    const csv = {
      value: buildCSV(grid),
      ext: 'csv',
    };
    const next = buildCompressed(csv.value);
    const compressed = LZString.compressToBase64(next);
    downloadData(csv);
    const url = getUrl(window.location.href, location);
    const file = createURLFile(`${url}?${compressed}`);
    downloadData(file);
  };

  const parse = (search: string) => {
    // const searchString = search.startsWith('?') ? search.substring(1) : search;
    // const uncompressed = LZString.decompressFromBase64(searchString);
    // if (!uncompressed) {
    //   return;
    // }
    // const result = buildUncompressed(uncompressed);
    // setCanvas({
    //   ...canvas,
    //   grid: result,
    // });
  };


  const upload = () => { };
  return [
    download,
    upload,
    parse,
  ];
};

export default useCSV;
