import atoms from '@atoms';
import { useRecoilState } from 'recoil';

interface UpdateIsStitchHotkeyPressed {
  (nextState: boolean): boolean;
}
type UseIsStitchHotkeyPressedResult = [boolean, UpdateIsStitchHotkeyPressed];

interface UseIsStitchHotkeyPressed {
  (): UseIsStitchHotkeyPressedResult
}

const useIsStitchHotkeyPressed: UseIsStitchHotkeyPressed = () => {
  const [stitch, sstStitch] = useRecoilState(atoms.stitch);

  const update = (option: boolean): boolean => {
    sstStitch((prev) => ({
      ...prev,
      hotkeyPressed: option,
    }));
    return option;
  };

  return [stitch.hotkeyPressed, update];
};

export default useIsStitchHotkeyPressed;
