import { editor, grid } from '@hooks';
import { Dropdown } from 'gestalt';
import React, { FunctionComponent, RefObject, useEffect, useRef } from 'react';
import buttons from './buttons';
import MenuItem from './item';

interface MenuProps {
  axis: App.Axis;
  positionIndex: number;
  anchorRef: RefObject<HTMLDivElement>;
}

const Menu: FunctionComponent<MenuProps> = (props) => {
  const { anchorRef, axis, positionIndex } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const menuButtons = axis === 'column' ? buttons.xAxis : buttons.yAxis;
  const [, setControlState] = editor.useControlState();
  const [change] = grid.useChange();

  useEffect(
    () => {
      window.addEventListener('keydown', keydown);

      return () => {
        window.removeEventListener('keydown', keydown);
      };
    },
    [],
  );

  const keydown = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      setControlState('openMenu', null);
    }
  };

  if (!anchorRef.current) {
    return null;
  }


  const closeMenu = () => {
    setControlState('openMenu', null);
  };

  const onSelect = <T extends App.ClickPayload>(payload: T) => {
    payload.event.stopPropagation();

    const value = JSON.parse(payload.item.value) as Change.Button;;
    switch (value.type) {
      case 'add': {
        change({
          type: 'add',
          axis,
          index: positionIndex + value.indexOffset,
        });
        break;
      }
      case 'remove': {
        change({
          type: 'remove',
          axis,
          index: positionIndex + value.indexOffset,
        });
        break;
      }
      case 'copy': {
        change({
          type: 'copy',
          axis,
          positionIndex,
          indexOffset: value.indexOffset,
        });
        break;
      }
    }
    closeMenu();
  };

  return (
    <Dropdown
      anchor={anchorRef.current}
      idealDirection={axis === 'row' ? 'left' : 'down'}
      id="truncation-dropdown-example"
      onDismiss={closeMenu}
    >
      {menuButtons.map((button) =>
        <Dropdown.Item
          key={button.label}
          onSelect={onSelect}
          option={{
            value: `${JSON.stringify(button)}`,
            label: button.label,
          }}
        >
          <MenuItem
            button={button}
          />
        </Dropdown.Item>,
      )}
    </Dropdown>
  );
};

export default Menu;
