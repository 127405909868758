import atoms from '@atoms';
import { List } from 'immutable';
import { useRecoilState } from 'recoil';

const useClear = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);

  const clear = () => {
    setCanvas({
      ...canvas,
      grid: List([]),
    });
  };

  return [clear];
};

export default useClear;
