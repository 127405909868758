import { optionsAtom } from '@atoms';
import { useRecoilState } from 'recoil';

interface SetOption<T extends keyof App.Options> {
  (value: App.Options[T]): App.Options[T]
}
type Option<T extends keyof App.Options> = [App.Options[T], SetOption<T>];

const useOption = <T extends keyof App.Options>(key: T) => {
  const [options, setOptions] = useRecoilState(optionsAtom);

  const setOption: SetOption<T> = (value: App.Options[T]): App.Options[T] => {
    setOptions((prev) => ({
      ...prev,
      [key]: value,
    }));
    return options[key];
  };

  const option = options[key];

  return [
    option,
    setOption,
  ] as Option<T>;
};

export default useOption;
