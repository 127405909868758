import settings from '@settings';
import { List, Record } from 'immutable';
import { atom } from 'recoil';


const history = Record({
  index: -1,
  items: List([]),
});

const defaultState: Atoms.Editor = {
  isUpdating: false,
  isDragging: settings.DEFAULT_IS_DRAGGING,
  history: new history(),
  mouseAction: 'stitch',
  drag: null,
  lastDrag: null,
  hotkeyPressed: null,
};
const editorAtom = atom({
  key: 'EditorAtom',
  default: defaultState,
});

export default editorAtom;
