import atoms from '@atoms';
import { useRecoilState } from 'recoil';

const useClear = () => {
  const [canvas, setCanvas] = useRecoilState(atoms.canvas);

  const clear = () => {
    const nextCanvas = canvas.grid.map((row) => {
      const nextCells = row.get('cells').map((cell) =>
        cell.set('isStitched', false),
      );
      return row.set('cells', nextCells);
    });

    setCanvas({
      ...canvas,
      grid: nextCanvas,
    });
  };

  return [clear];
};

export default useClear;
