
import { selector } from 'recoil';
import applicationAtom from './application';
import canvasAtom from './canvas';

const loadingSelector = selector({
  key: 'LoadingSelector',
  get: ({ get }) => {

    const { loading } = get(applicationAtom);
    const { container } = get(canvasAtom);
    const values = Object.values(loading);
    const canvasIsLoading = container.width === 0 && container.height === 0;
    const all = values.every((value) => value === true) && canvasIsLoading;
    const any = values.some((value) => value === true) || canvasIsLoading;

    const state: App.Loading = {
      ...loading,
      canvas: canvasIsLoading,
      all,
      any,
    };
    return state;
  },
});

export default loadingSelector;
