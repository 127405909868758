
import Components, { Title } from '@components';
import { useSize } from '@hooks';
import { Box } from 'gestalt';
import React, { FunctionComponent } from 'react';

const Studio: FunctionComponent = (props) => {
  const { children } = props;
  const [size] = useSize();
  const { isMobile } = size;

  return (
    <Box
      alignItems="center"
      color="lightGray"
      height="100vh"
      display="flex"
      direction="column"
      justifyContent="between"
      paddingX={isMobile ? 4 : 8}
      paddingY={4}
    >
      <Title />
      {children}
      <Components.Ads.Footer />
    </Box>
  );
};

export default Studio;
