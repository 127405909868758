import styled from 'styled-components';

export const RowContainer = styled.div`
  display: grid;
`;

export const Container = styled.div`
  height: max-content;
  width: max-content;
`;

interface CanvasContainerProps {
  isLoading: boolean;
}

export const CanvasContainer = styled.div<CanvasContainerProps>`
  top: 0;
  &:hover {
    cursor: none;
  }
`;

interface RowProps {
  rowHeight: number;
}

export const Row = styled.div<RowProps>`
  margin: 0;
  padding: 0;
  height: ${(props) => props.rowHeight}px;
  display: flex;
  flex-direction: row;
`;

interface SquareProps {
  squareWidth: number;
  squareHeight: number;
}

export const BaseSquare = styled.div<SquareProps>`
  width: ${(props) => props.squareWidth}px;
  height: ${(props) => props.squareHeight}px;
  display: inline-block;
`;

export const ColumnPosition = styled(BaseSquare)`
  font-size: ${({ squareWidth }) => squareWidth * .75}px;
  font-family: 'Source Code Pro', monospace;
`;

export const RowPosition = styled(ColumnPosition)`
  text-align: right;
`;

export const Position = styled.div`
  display: inline;
  margin: 0 0.3em 0 0;
  font-size: 0.8em;
  vertical-align: top;
  font-family: 'Source Code Pro', monospace;
`;

export const Square = styled(BaseSquare)`
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  position: relative;
  box-sizing: border-box;
`;

