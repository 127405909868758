import { UI } from '@components';
import { Box } from 'gestalt';
import React, { FunctionComponent } from 'react';
import colors from './colors';

interface PickProps {
  selectedColor: string | null;
  selectColor(color: string): void;
}

const Pick: FunctionComponent<PickProps> = (props) => {
  const { selectedColor, selectColor } = props;

  const result = Array
    .from({ length: colors[0].length })
    .map((_row, index) => colors.map((row) => row[index]));

  return (
    <Box marginBottom={4} display="flex" alignItems="center" direction="column">
      <Box display="flex">
        {result.map((row, index) =>
          <Box key={index} direction="column" display="flex">
            {row.map((item) =>
              <Box marginTop={1}>
                <UI.ColorNode
                  onClick={() => selectColor(item)}
                  isButton
                  isSelected={selectedColor === item}
                  key={item}
                  hex={item}
                />
              </Box>,
            )}
          </Box>,
        )}
      </Box>
    </Box>
  );
};

export default Pick;
