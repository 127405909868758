import { Box, Heading, Text } from 'gestalt';
import React, { FunctionComponent } from 'react';

interface ContentProps {
  message?: ActionMenu.ConfirmMessage;
}

const Content: FunctionComponent<ContentProps> = (props) => {
  const { message } = props;

  if (!message) {
    return (
      <Heading size="sm">Are you sure?</Heading>
    );
  }

  return (
    <Box padding={4}>
      <Heading size="md">{message.heading}</Heading>
      <Text align="center">
        {message.body}
      </Text>
    </Box>
  );
};

export default Content;
