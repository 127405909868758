import styled from 'styled-components';

export const Setup = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
