import { useSize } from '@hooks';
import { Box, Column } from 'gestalt';
import React, { FunctionComponent, MutableRefObject } from 'react';
import Actions from './actions';
import Color from './color';
import History from './history';

interface ControlPanelProps {
  outputRef: MutableRefObject<HTMLDivElement | null>
}

const ControlPanel: FunctionComponent<ControlPanelProps> = (props) => {
  const { outputRef } = props;
  const [size] = useSize();
  const { isMobile } = size;

  return (
    <Box display="flex" width="100%" marginBottom={2} marginTop={4}>
      <Column span={isMobile ? 11 : 10}>
        <Box display="flex" alignItems="stretch" maxWidth={500} alignContent="center">
          <History />
          <Color />
        </Box>
      </Column>
      <Column span={isMobile ? 1 : 2}>
        <Actions outputRef={outputRef} />
      </Column>
    </Box>
  );
};

export default ControlPanel;
