import { Record } from 'immutable';

const getStitch = (indices: App.Indices, grid: App.Grid, defaultStitch?: Partial<App.Stitch>): App.ImmutableStitch => {
  const cell = grid
    .get(indices.rowIndex)
    ?.get('cells')
    ?.get(indices.columnIndex);
  if (cell) {
    return cell as App.ImmutableStitch;
  }
  const Factory = Record({
    id: defaultStitch?.id || `${Math.random()}`,
    isStitched: defaultStitch?.isStitched || false,
    color: defaultStitch?.color || '#FFFFFF',
  });
  return new Factory();
};

export default getStitch;
