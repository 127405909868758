import { UI } from '@components';
import Button from '@components/button';
import { application } from '@hooks';
import React, { Fragment, FunctionComponent, useState } from 'react';
import Modal from './modal';

const Color: FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [color] = application.useStitchColor();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Fragment>
      <Button onClick={openModal} snug>
        <UI.ColorNode
          width="50px"
          isFluidWidth
          pointerCursor
          hex={color}
        />
      </Button>
      {isModalOpen &&
        <Modal close={closeModal} />
      }
    </Fragment>
  );
};

export default Color;
