import { userAtom } from '@atoms';
import { useRecoilState } from 'recoil';
import api from '../api';

type UserAuth = [currentUser: Atoms.User, getCurrentUser: () => Promise<Atoms.User | null>];

interface UseUserAuth {
  (): UserAuth
}

const useUserAuth: UseUserAuth = () => {
  const [user, setUser] = useRecoilState(userAtom);

  const currentUser = (response: App.User | null): Atoms.User => {
    if (!response) {
      return {
        isLoading: false,
        currentUser: null,
        isLoggedIn: false,
      };
    }

    return {
      isLoading: false,
      currentUser: {
        email: response.email,
      },
      isLoggedIn: true,
    };
  };
  const getCurrentUser = async (): Promise<Atoms.User | null> => {
    const response = api.getCurrentUser();
    const nextUser = currentUser(response);
    setUser(nextUser);
    return nextUser;
  };

  return [user, getCurrentUser];
};

export default useUserAuth;
