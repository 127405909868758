import { maxSelector } from '@atoms';
import { useRecoilValue } from 'recoil';

const useSquareSize = () => {
  const { squareSize } = useRecoilValue(maxSelector);

  return [squareSize];
};

export default useSquareSize;
