import { editor } from '@hooks';
import React, { FunctionComponent, MouseEvent, useEffect, useRef, useState } from 'react';
import Menu from './menu';
import * as Styled from './styled';

interface PositionProps {
  position: string;
  index: number;
  axis: App.Axis;
  isLast: boolean;
}

const Position: FunctionComponent<PositionProps> = (props) => {
  const { position, axis, index, isLast } = props;
  const [controlState, setControlState] = editor.useControlState();
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isContainerHovered, setIsContainerHovered] = useState(false);

  const isSelected = !!controlState.openMenu && controlState.openMenu.axis === axis && controlState.openMenu.index === index;

  const menuLocation: Atoms.MenuLocation = {
    axis,
    index,
  };

  useEffect(
    () => {
      if (containerRef.current) {
        containerRef.current.addEventListener('mouseenter', mouseenterContainer);
        containerRef.current.addEventListener('mouseleave', mouseleaveContainer);
      }

      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener('mouseenter', mouseenterContainer);
          containerRef.current.removeEventListener('mouseleave', mouseleaveContainer);
        }
      };

    },
    [containerRef],
  );


  const mouseenterContainer = () => {
    if (controlState.openMenu === null) {
      setIsContainerHovered(true);
    }
  };

  const mouseleaveContainer = () => {
    if (controlState.openMenu === null) {
      setIsContainerHovered(false);
    }
  };

  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setControlState('openMenu', menuLocation);
  };

  const isHovered = isContainerHovered || isSelected;

  const devPosition = () => axis === 'column' ? `${index === 0 ? '' : `↖${index - 1}`}` : `${index}↗`;

  return (
    <Styled.PositionContainer>
      {!isLast &&
        <Styled.PositionHover
          isHovered={isHovered}
          ref={containerRef}
          axis={axis}
          onClick={onClick}
        >
          <Styled.DropdownContainer ref={dropdownRef} />
          {isSelected &&
            <Menu
              anchorRef={dropdownRef}
              positionIndex={index}
              axis={axis}
            />
          }
        </Styled.PositionHover>
      }
      <Styled.PositionNumber
        axis={axis}
      >
        {position}
      </Styled.PositionNumber>
    </Styled.PositionContainer>
  );
};

export default Position;
