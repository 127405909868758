import { optionsAtom } from '@atoms';
// import firebase from 'firebase';
// import { Button } from 'gestalt';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Save: FunctionComponent = () => {
  const options = useRecoilValue(optionsAtom);
  // const { pixels } = useRecoilValue(pixelsAtom);
  const history = useHistory();

  return null;
  // if (pixels.length === 0) {
  // }

  // const onClick = () => {
  //   const database = firebase.database();
  //   const saveRef = database.ref('saves').push();
  //   saveRef.set({
  //     pixels,
  //     options,
  //   });
  //   history.push(`/${saveRef.key}`);
  // };

  // return (
  //   <Button
  //     color="blue"
  //     text="Save"
  //     onClick={onClick}
  //   />
  // );
};

export default Save;
