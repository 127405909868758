const getUrl = (url: string | null) => {
  if (!url) {
    return null;
  }
  const isHttp = window.location.protocol === 'http:';
  if (isHttp) {
    return url.startsWith('http:') ? url : url.replace('http:', 'https:');
  }

  return url.startsWith('https:') ? url : url.replace('http:', 'https:');
};

const loadFont = async (font: App.Font, loadedFonts: string[]) => {
  const loadedFontsCopy = loadedFonts.slice();
  if (loadedFontsCopy.includes(font.family)) {
    return false;
  }
  const url = getUrl(font.file);
  const fontFace = new FontFace(font.family, `url(${url})`);
  const loaded = await fontFace.load();
  document.fonts.add(loaded);
  return true;
};

const textMagic: App.TextMagic = async (text, resolution, font, loadedFonts) => {
  const width = 1;
  const lines = false;

  if (!resolution) {
    resolution = 30;
  }

  const canvas = textMagic.canvas || (textMagic.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Context not returned by canvas');
  }
  const stitches = [];
  const didLoadFont = await loadFont(font, loadedFonts);
  const fontString = `${resolution}px ${font.family}`;

  context.font = fontString;
  canvas.width = context.measureText(text).width;
  canvas.height = resolution;
  context.textBaseline = 'top';
  context.font = fontString;
  context.lineWidth = width;
  context.clearRect(0, 0, canvas.width, canvas.height);

  if (!lines || lines === false) {
    context.fillText(text, 0, 0);
  } else {
    context.strokeText(text, 0, 0);
  }

  const image = context.getImageData(0, 0, canvas.width, canvas.height);
  const { buffer } = image.data;
  const data32 = new Uint32Array(buffer);

  for (let i = 0; i < data32.length; i++) {
    if (data32[i] & 0xff000000) {
      stitches.push({
        x: i % canvas.width,
        y: (i / canvas.width) | 0,
      });
    }
  }

  return {
    stitches,
    didLoadFont,
  };
};


export default textMagic;
