import getCurrentUser from './getCurrentUser';
import getFonts from './getFonts';
import getSave from './getSave';
import startFirebase from './startFirebase';
import userCreate from './userCreate';
import userLogin from './userLogin';
import userLogout from './userLogout';

export { default as getCurrentUser } from './getCurrentUser';
export { default as getFonts } from './getFonts';
export { default as getSave } from './getSave';
export { default as startFirebase } from './startFirebase';
export { default as userCreate } from './userCreate';
export { default as userLogin } from './userLogin';
export { default as userLogout } from './userLogout';

const api = {
  userCreate,
  getCurrentUser,
  getFonts,
  getSave,
  userLogin,
  userLogout,
  startFirebase,
};

export default api;
