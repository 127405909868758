import PointerBox from './pointerBox';
import * as Styled from './styled';
import StyledPointerBox from './styledPointerBox';

const UI = {
  Absolute: Styled.Absolute,
  Border: Styled.Border,
  ColorNode: Styled.ColorNode,
  ColorWrap: Styled.ColorWrap,
  DefaultCursor: Styled.DefaultCursor,
  Full: Styled.Full,
  Inline: Styled.Inline,
  InlineBlock: Styled.InlineBlock,
  NegativeMargin: Styled.NegativeMargin,
  Relative: Styled.Relative,
  PointerCursor: Styled.PointerCursor,
  PointerBox,
  StyledPointerBox,
  UploadImage: Styled.UploadImage,
  UploadInput: Styled.UploadInput,
};

export default UI;
