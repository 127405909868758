
import { sizes } from '@utils';
import { selector } from 'recoil';
import canvasAtom from './canvas';

const maxSelector = selector({
  key: 'MaxSelector',
  get: ({ get }) => {

    const { grid, container } = get(canvasAtom);
    const squareSize = sizes.pixelsToSquareSize(grid, container);

    return {
      columnCount: grid.get(0)?.get('cells').size || 0,
      rowCount: grid.size,
      container,
      squareSize,
    };
  },
});

export default maxSelector;
