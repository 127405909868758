import { atom } from 'recoil';

const defaultAtom: App.Options = {
  text: '',
  loadedFontFamilies: [],
  font: {
    family: 'Roboto',
    file: 'http://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
  },
  resolution: 20,
};

const optionsAtom = atom({
  key: 'OptionsAtom',
  default: defaultAtom,
});

export default optionsAtom;
