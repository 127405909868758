import { useOnNavigation } from '@hooks';
import { OnLinkNavigationProvider } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

const Wrappers: FunctionComponent = (props) => {
  const { children } = props;

  return (
    <RecoilRoot>
      <OnLinkNavigationProvider onNavigation={useOnNavigation}>
        <BrowserRouter>
          {children}
        </BrowserRouter>
      </OnLinkNavigationProvider>
    </RecoilRoot>
  );
};

export default Wrappers;
