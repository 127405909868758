import atoms from '@atoms';
import { localStorage } from '@utils';
import { useRecoilState } from 'recoil';

const getApplicationKey = <T extends App.LocalStorageKey>(key: T) => {
  switch (key) {
    case 'hotkeys-enabled': return {
      key: 'isEnabled',
      nested: 'hotkeys',
    };
    case 'hide-hotkey-info': return {
      key: 'isInfoHidden',
      nested: 'hotkeys',
    };
    case 'is-dark-mode': return {
      key: 'isDarkMode',
      nested: false,
    };
    default:
      throw new Error(`Bad key provided: ${key}`);
  }
};

const useLocalStorage = () => {
  const [application, setApplication] = useRecoilState(atoms.application);

  const setLocalStorage = <T extends App.LocalStorageKey>(key: T, value: App.LocalStorageValues[T]): App.LocalStorageValues[T] | null => {
    const applicationKey = getApplicationKey(key);
    const hotkeys = applicationKey.nested && applicationKey.nested === 'hotkeys' ? {
      ...application.hotkeys,
      [applicationKey.key]: value,
    } : application.hotkeys;

    const nextApplication = !applicationKey.nested ? {
      ...application,
      [applicationKey.key]: value,
      hotkeys,
    } : {
      ...application,
      hotkeys,
    };

    setApplication(nextApplication);
    localStorage.set(key, value);
    return value;
  };

  const getLocalStorage = <T extends App.LocalStorageKey>(key: T): App.LocalStorageValues[T] | null => {
    switch (key) {
      case 'hotkeys-enabled': return application.hotkeys.isEnabled || false;
      case 'is-dark-mode': return application.isDarkMode || false;
      case 'hide-hotkey-info': return application.hotkeys.isInfoHidden || false;
      default: return null;
    }
  };

  return [
    getLocalStorage,
    setLocalStorage,
  ] as const;
};

export default useLocalStorage;
