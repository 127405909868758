import { TapArea } from 'gestalt';
import React, { FunctionComponent } from 'react';

interface WrapperProps {
  isDisabled?: boolean;
  onTap(): void;
  onClickDataLayer?: string;
}

const Wrapper: FunctionComponent<WrapperProps> = (props) => {
  const { onClickDataLayer, isDisabled, onTap, children } = props;

  const onClick = () => {
    if (onClickDataLayer) {
      window.dataLayer.push({
        event: onClickDataLayer,
      });
    }
  };

  return (
    <TapArea
      fullHeight
      rounding={4}
      onTap={onTap}
      disabled={isDisabled}
    >
      {children}
    </TapArea>
  );
};

export default Wrapper;
