import { maxSelector } from '@atoms';
import { useRecoilValue } from 'recoil';

const useRowNumber = (rowIndex: number): [number] => {
  const { rowCount } = useRecoilValue(maxSelector);
  const rowNumber = rowCount - rowIndex;

  return [
    rowNumber,
  ];
};

export default useRowNumber;
