import settings from '@settings';
import { Record } from 'immutable';

const makeStitch = (id?: string): App.ImmutableStitch => {
  const stitch = {
    ...settings.DEFAULT_GRID_ITEM,
    id: id || `${Math.random()}`,
  };
  const Factory = Record(stitch);
  return new Factory();
};

export default makeStitch;
