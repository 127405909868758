import { Box, BoxProps } from 'gestalt';
import React, { FunctionComponent } from 'react';
import * as Styled from './styled';

interface StyledPointerBoxProps extends BoxProps {
  isHoverOutline?: boolean;
}

const StyledPointerBox: FunctionComponent<StyledPointerBoxProps> = (props) => {
  const { isHoverOutline, ...rest } = props;
  return (
    <Styled.StyledPointerBox
      isHoverOutline={isHoverOutline}
    >
      <Box {...rest} />
    </Styled.StyledPointerBox>
  );
};

export default StyledPointerBox;
