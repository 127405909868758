import atoms from '@atoms';
import { useSetRecoilState } from 'recoil';

const useCopy = () => {
  const setCanvas = useSetRecoilState(atoms.canvas);

  const copy = (options: Change.CopyOptions) => {
    setCanvas((canvas) => {
      const { positionIndex, indexOffset, axis } = options;
      const placementIndex = positionIndex + indexOffset;

      if (axis === 'row') {
        const row = canvas.grid.get(positionIndex);
        if (row) {
          const nextCells = row.get('cells')?.map((cell) => ({
            ...cell,
            id: `${Math.random()}`,
          }));
          const nextRow = {
            ...row,
            id: `${Math.random()}`,
            cells: nextCells,
          };
          const grid = canvas.grid.splice(placementIndex, 0, nextRow);

          return {
            ...canvas,
            grid,
          };
        }
      }

      const nextGrid = canvas.grid.map((row) => {
        const cells = row.get('cells');
        const cell = cells.get(positionIndex);
        if (cell) {
          const nextCell = cell.set('id', `${Math.random()}`);
          const nextCells = cells.splice(positionIndex, 0, nextCell);
          return row.set('cells', nextCells);
        }
        return row;
      });

      // const nextGrid = grid.map((row) => {
      //   const rowCopy = [...row.cells];
      //   const cell = rowCopy[positionIndex];
      //   if (cell) {
      //     const nextCell: App.Stitch = {
      //       ...cell,
      //       id: `${Math.random()}`,
      //     };
      //     rowCopy.splice(placementIndex, 0, nextCell);
      //   }
      //   return {
      //     ...row,
      //     cells: rowCopy,
      //   };
      // });


      return {
        ...canvas,
        grid: nextGrid,
      };
    });
  };

  return [
    copy,
  ];
};

export default useCopy;
