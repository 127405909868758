export { default as Button } from './button';
export { default as Gestalt } from './gestalt';
export { default as Inputs } from './inputs';
export { default as Loading } from './loading';
export { default as Modals } from './modals';
export { default as Setup } from './setup';
export { default as Title } from './title';
export { default as UI } from './ui';
export type { NumberRef };
export type { TextRef };

import Ads from './ads';
import { NumberRef, TextRef } from './inputs';
import PreventFocusBorder from './preventFocusBorder';
import UI from './ui';

const Components = {
  Ads,
  PreventFocusBorder,
  UI,
};

export default Components;

