const KEY = 'needlepoint.studio';

const boolean = {
  to: (value: App.StringBoolean): boolean => {
    return value === 'true';
  },
  from: (value: boolean): App.StringBoolean => {
    return value ? 'true' : 'false';
  },
};

const number = {
  to: (value: App.StringNumber): number => {
    return parseInt(value, 10);
  },
  from: (value: number): App.StringNumber => {
    return value.toString();
  },
};

const convert = {
  to: <T extends boolean | number | string>(value: App.StringBoolean | App.StringNumber | string): T => {
    if (typeof value === 'string') {
      if (value === 'true' || value === 'false') {
        return boolean.to(value) as T;
      }
      if (value.includes('.')) {
        return number.to(value) as T;
      }
    }
    return value as T;
  },
  from: (value: boolean | number | string): App.StringBoolean | App.StringNumber | string => {
    if (typeof value === 'boolean') {
      return boolean.from(value);
    }
    if (typeof value === 'number') {
      return number.from(value);
    }
    return value;
  },

};

const get = <T extends App.LocalStorageKey>(key: T, fallback?: App.LocalStorageValues[T]): App.LocalStorageValues[T] | null => {
  const buildKey = `${KEY}.${key}`;
  const local = window.localStorage.getItem(buildKey);
  if (local === null) {
    if (fallback) {
      return fallback;
    }
    return null;
  }
  return convert.to<App.LocalStorageValues[T]>(local);
};

const set = <T extends App.LocalStorageKey>(key: T, value: App.LocalStorageValues[T]) => {
  const buildKey = `${KEY}.${key}`;
  const setValue = convert.from(value);
  window.localStorage.setItem(buildKey, setValue);
};

const localStorage = {
  get,
  set,
};

export default localStorage;
