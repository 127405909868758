import makeStitch from '@utils/makeStitch';
import useStitchColor from '../application/useStitchColor';
import useCanvasRow from './useCanvasRow';

interface UseCanvasCell {
  (rowIndex: number, columnIndex: number): [App.ImmutableStitch]
}

const useCanvasCell: UseCanvasCell = (rowIndex: number, columnIndex: number) => {
  const [row] = useCanvasRow(rowIndex);
  const [color] = useStitchColor();

  const defaultStitch = makeStitch().set('color', color);

  const cell = row.get('cells')?.get(columnIndex) || defaultStitch;

  return [
    cell,
  ];
};

export default useCanvasCell;
